import React from 'react';
import { IconContext } from 'react-icons';
import { FaXTwitter, FaInstagram, FaWhatsapp, FaEye, FaPlus, FaMinus } from "react-icons/fa6";
import { FaEnvelope, FaFacebookF, FaEdit, FaHome, FaFile, FaShoppingBasket, FaCog, FaComments, 
        FaQuestion, FaLock, FaCircle, FaUserPlus, FaArrowRight, FaArrowLeft, FaCheck, FaBan, FaRegClock,
        FaPhone, FaCaretDown, FaPaperPlane, FaUserTimes, FaUserCheck, FaTachometerAlt, FaExclamationTriangle } from "react-icons/fa";
import { BiLogoGmail, BiChevronDown, BiChevronRight, BiChevronLeft, BiLogInCircle, BiLogOutCircle, BiUserCheck } from "react-icons/bi";
import { CiSearch } from "react-icons/ci";
import { BsHeart, BsHeartFill, BsCart3, BsCartCheck, BsCartCheckFill } from "react-icons/bs";
import { MdLogout, MdLogin, MdClose, MdDeleteForever, MdOutlineFaceRetouchingNatural, MdEmail, MdErrorOutline, MdOutlinePending } from "react-icons/md";
import { TbTruckDelivery, TbMessageCircle } from 'react-icons/tb';
import { IoCart, IoCartOutline, IoPhonePortraitOutline, IoLocation, IoFilterOutline, IoSettingsOutline } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { HiMenuAlt2 } from "react-icons/hi";
import { FcApproval, FcDisapprove } from "react-icons/fc";
import { VscEye, VscEyeClosed, VscLayoutSidebarLeft } from "react-icons/vsc";
// Import other icons as needed

const iconComponents = {
    FaEnvelope, FaFacebookF, FaEdit, FaHome, FaFile, FaShoppingBasket, FaQuestion, FaCog, FaComments, 
    FaLock, FaCircle, FaUserPlus, FaArrowRight, FaArrowLeft, FaCheck, FaPhone, FaCaretDown, FaBan, FaPaperPlane,
    FaUserTimes, FaUserCheck, FaTachometerAlt, FaExclamationTriangle, FaRegClock,
    FaXTwitter, FaInstagram, FaWhatsapp, FaEye, FaPlus, FaMinus,
    BiLogoGmail, BiChevronDown, BiChevronLeft, BiChevronRight, BiLogInCircle, BiLogOutCircle, BiUserCheck,
    BsHeart, BsHeartFill, BsCartCheck,
    CiSearch,
    MdLogout, MdLogin, MdClose, MdDeleteForever, MdOutlineFaceRetouchingNatural, MdEmail, MdErrorOutline, MdOutlinePending,
    TbTruckDelivery, TbMessageCircle,
    IoCart, IoCartOutline, IoPhonePortraitOutline, IoLocation, IoFilterOutline, IoSettingsOutline,
    RxDashboard,
    HiMenuAlt2,
    FcApproval, FcDisapprove,
    VscEye, VscEyeClosed, VscLayoutSidebarLeft,
  // Add other icons here
};

const Icon = ({ 
    name, 
    size = '1em', 
    color = 'currentColor', 
    style = {}, 
    className = ''
}) => {
    const IconComponent = iconComponents[name];
  
    if (!IconComponent) {
      console.warn(`Icon ${name} not found`);
      return null;
    }
  
    return (
        <IconContext.Provider value={{ size, color }}>
            <div 
                style={{ display: 'inline-block', ...style }}
                className={className}
            >
                <IconComponent />
            </div>
        </IconContext.Provider>
    );
};
  
export default Icon;

// Usage:
// <Icon name="FaShoppingBag" size="1.5em" color="#333" />
// import Icon from "../icon/Icon";
