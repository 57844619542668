import axios from "axios";
import { UserProps } from "../../types/Types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import Icon from '../../components/icon/Icon';
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import PaymentOptions from "../../components/payment/PaymentOptions";
import PaystackForm from "../../components/payment/PaystackForm";
import DirectTransferForm from "../../components/payment/DirectTransferForm";
import UpdateShopperModal from "../../components/modals/UpdateShopperModal";
import LoaderCopy from "../../components/loader/LoaderCopy";

const Checkout = () => {
    const [cartData, setCartData] = useState<any>([]);
    const [cartDetails, setCartDetails] = useState<any>([]);
    const [userDetails, setUserDetails] = useState<UserProps | null>(null);
    const [user_id, setUserId] = useState(null);
    const [showDeliveryInfo, setShowDeliveryInfo] = useState<boolean>(true);
    const [showPaymentMethod, setShowPaymentMethod] = useState<boolean>(false);
    const [showPayment, setShowPayment] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    // function to ensure if token has expired, redirects user to login
    const handleJwtExpired = () => {
        alert('Your session has expired. Please log in again.');
        sessionStorage.clear();
        navigate("/login?message=Your session has expired. Please log in again.");
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        axios
            .get(`${process.env.REACT_APP_Base_url}/shoppers/me`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("shopper_token")}`,
                },
            })
            .then((res) => {
            //console.log(res.data);
            if (res.data.status === 200) {
                setUserDetails(res.data.user);
                setIsLoading(false);
                } else if (res.data.message === "jwt expired" && res.data.status === 401) {
                    handleJwtExpired();
                }
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${process.env.REACT_APP_Base_url}/shoppers/get_user_cart_items`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("shopper_token")}`,
                },
            })
            .then((res) => {
                //console.log(res.data);
                setCartData(res.data);
                setCartDetails(res.data.product);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            }
        );
    }, []);

    const handleToggleDeliveryInfo = () => {
        // event.preventDefault(); // Prevent the default link behavior
        setShowDeliveryInfo(!showDeliveryInfo);
        setShowPaymentMethod(false);
        setShowPayment(false);
    };

    const handleTogglePaymentMethod = () => {
        // event.preventDefault(); // Prevent the default link behavior
        setShowPaymentMethod(!showPaymentMethod);
        setShowDeliveryInfo(false);
        setShowPayment(false);
    };

    const handleContinueToPaymentMethod = () => {
        window.scrollTo(0, 0);
        setShowDeliveryInfo(false);
        setShowPaymentMethod(true);
        setShowPayment(false);
    };

    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
        // Clear error message when the checkbox is checked
        setErrorMessage('');
    };

    const validatePaymentMethod = () => {
        if (!selectedOption) {
        setErrorMessage('Please select a payment method.');
        return false;
        }
        return true;
    };

    const validateTerms = () => {
        if (!isCheckboxChecked) {
        setErrorMessage('You must accept the terms and conditions before proceeding.');
        return false;
        }
        return true;
    };

    const handleContinueToPayment = () => {
        if (!validatePaymentMethod() || !validateTerms()) {
        return;
    }
        // Show the selected payment form and collapse/expand sections
        setShowPayment(true);
        setShowPaymentMethod(false);
        setShowDeliveryInfo(false);
    };

    return (
        <>
            <HeaderCopy />   

            <div id="wrapper">  
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="shopping-cart shopping-cart-shipping">  
                                <div className="row">  
                                    <div className="col-sm-12 col-lg-8">
                                        <div className="left">
                                            <h1 className="cart-section-title">Checkout</h1>
                                            <div className={`tab-checkout ${showDeliveryInfo ? "tab-checkout-open m-t-0" : "tab-checkout-closed"}`}>
                                                <a href="javascript:void(0)">
                                                    <h2 className="title" onClick={handleToggleDeliveryInfo}>1.&nbsp;&nbsp;Delivery Information&nbsp;</h2>
                                                </a>
                                                {!showDeliveryInfo && (                         
                                                    <a href="javascript:void(0)" className="link-underlined edit-link" onClick={handleToggleDeliveryInfo}>Edit</a>
                                                )}
                                                {showDeliveryInfo && (
                                                    <div className="tab-checkout tab-checkout-open m-t-0">
                                                        <p className="text-muted">Is your delivery details up to date?</p>
                                                        {isLoading ? (
                                                            <LoaderCopy loading={isLoading} />
                                                        ) : (
                                                            <div className="checkout__form">
                                                                <>
                                                                    {userDetails && (
                                                                        <div className="col-lg-8 col-md-6">
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="checkout__input">
                                                                                        <p>
                                                                                            First Name<span>*</span>
                                                                                        </p>
                                                                                        <input type="text" value={userDetails.first_name} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="checkout__input">
                                                                                        <p>
                                                                                            Last Name<span>*</span>
                                                                                        </p>
                                                                                        <input type="text" value={userDetails.last_name} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="checkout__input">
                                                                                        <p>
                                                                                            Email<span>*</span>
                                                                                        </p>
                                                                                        <input type="text" value={userDetails.email} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="checkout__input">
                                                                                        <p>
                                                                                            Phone<span>*</span>
                                                                                        </p>
                                                                                        <input type="text" value={userDetails.contact} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="checkout__input">
                                                                                        <p>
                                                                                            Location<span>*</span>
                                                                                        </p>
                                                                                        <input type="text" value={userDetails.location} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            </div>
                                                        )}
                                            
                                                        <UpdateShopperModal />
                                            
                                                        <div className="form-group m-t-15">
                                                            <a href="/cart" className="link-underlined link-return-cart">&lt;&nbsp;Return to cart</a>
                                                            <button type="button" className="btn btn-lg btn-custom btn-continue-payment float-right" onClick={handleContinueToPaymentMethod}>
                                                                Continue to Payment Method
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                        
                                            {/* Payment method section */}             
                                            <div className={`tab-checkout ${showPaymentMethod ? "tab-checkout-open" : showDeliveryInfo ? "tab-checkout-closed-bordered" : "tab-checkout-closed"}`}>
                                                <a href="javascript:void(0)">
                                                    <h2 className="title" onClick={showDeliveryInfo ? undefined : handleTogglePaymentMethod}>2.&nbsp;&nbsp;Payment Method&nbsp;</h2>
                                                </a>
                                                {(!showDeliveryInfo && !showPaymentMethod) && (                         
                                                    <a href="javascript:void(0)" className="link-underlined edit-link" onClick={handleTogglePaymentMethod}>Edit</a>
                                                )}
                                                {showPaymentMethod && (
                                                    <>
                                                        {/* Render PaymentOptions component */}
                                                        <PaymentOptions selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                            
                                                        {/* Terms and conditions checkbox */}
                                                        <div className="form-group">
                                                            <div className="custom-control custom-checkbox custom-control-validate-input">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    name="terms" 
                                                                    id="checkbox_terms" 
                                                                    required
                                                                    data-gtm-form-interact-field-id="0"
                                                                    onChange={handleCheckboxChange}
                                                                    checked={isCheckboxChecked}
                                                                />
                                                                <label 
                                                                    htmlFor="checkbox_terms" 
                                                                    className="custom-control-label">
                                                                    I have read and agree to the&nbsp;
                                                                    <a href="/terms" className="link-terms" target="_blank"><strong>Terms & Conditions</strong></a>
                                                                </label>
                                                            </div>
                                                            {errorMessage && <p className="text-danger">{errorMessage}</p>} {/* Display error message if present */}
                                                        </div>

                                                        {/* Continue to payment button */}
                                                        <div className="form-group m-t-15">
                                                            <a href="/cart" className="link-underlined link-return-cart">&lt;&nbsp;Return to cart</a>
                                                            <button type="button" className="btn btn-lg btn-custom btn-continue-payment float-right" onClick={handleContinueToPayment}>
                                                                Continue to Payment <Icon name="MdArrowRightAlt" />
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            {/* Payment section */}
                                            <div className={`tab-checkout ${showPayment ? "tab-checkout-open" : showDeliveryInfo ? "tab-checkout-closed-bordered border-top-0" : "tab-checkout-closed-bordered"}`}>
                                                <h2 className="title">3. &nbsp;Payment</h2>
                                                {showPayment && (
                                                    <>
                                                        {selectedOption === 'bankTransfer' && <DirectTransferForm />}
                                                        {selectedOption === 'paystack' && <PaystackForm />}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-4 order-summary-container">
                                        <h2 className="cart-section-title">Order Summary ({cartDetails ? cartDetails.length : 0})</h2>
                                        {isLoading ? (
                                            <LoaderCopy loading={isLoading} />
                                        ) : (
                                            <div className="right">
                                                <div className="cart-order-details">
                                                    {cartDetails && cartDetails.map(
                                                        (item: {
                                                            product_name: string;
                                                            product_image: string;
                                                            cart_quantity: number;
                                                            company_name: string;
                                                            total_cost: number;
                                                        }) => (
                                                            <>
                                                                <div className="item"> 
                                                                    <div className="item-left">
                                                                        <div className="img-cart-product">
                                                                            <a href="/details">
                                                                                <img 
                                                                                    src={item.product_image} 
                                                                                    data-src={item.product_image} 
                                                                                    alt={item.product_name} 
                                                                                    className="img-fluid img-product ls-is-cached lazyloaded" 
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="item-right">
                                                                        <div className="list-item">
                                                                            {item.product_name}{" "}
                                                                        </div>
                                                                        <div className="list-item seller">
                                                                            By&nbsp;{item.company_name}{" "}
                                                                        </div>
                                                                        <div className="list-item m-t-15">
                                                                            <label>Quantity:</label>
                                                                            <strong className="lbl-price">{item.cart_quantity}{" "}</strong>
                                                                        </div>
                                                                        <div className="list-item">
                                                                            <label>Price:</label>
                                                                            <strong className="lbl-price"><span>¢</span>{item.total_cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                                                                        </div>
                                                                    </div>                                  
                                                                </div>
                                                            </>
                                                        )
                                                    )}      
                                            
                                                    <div className="row-custom m-t-30 m-b-10">
                                                        <strong>Subtotal<span className="float-right"><span>¢</span>{cartData.cart_total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></strong>
                                                    </div>
                                                    <div className="row-custom">
                                                        <p className="line-seperator"></p>
                                                    </div>
                                                    <div className="row-custom">
                                                        <strong>Total<span className="float-right"><span>¢</span>{cartData.cart_total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></strong>
                                                    </div>
                                                </div>
                                            </div>
                                        )}            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Checkout;