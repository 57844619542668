import axios from "axios";
import React, { useEffect, useState } from "react";
import HeaderCopy from "../../components/header/HeaderCopy";
import Footer from "../../components/footer/Footer";
import { Link } from 'react-router-dom';
import { ProductProps } from "../../types/Types";
import Loader from "../../components/loader/Loader";
import Icon from '../../components/icon/Icon';

// payment logos
import visa from "../../assets/img/visa.svg";
import maestro from "../../assets/img/maestro.svg";
import mastercard from "../../assets/img/mastercard.svg";
import amex from "../../assets/img/amex.svg";
import discover from "../../assets/img/discover.svg";


const Cart = () => {
    const [cartData, setCartData] = useState<any>([]);
    const [cartDetails, setCartDetails] = useState<any>([]);
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    // calling the products active api
    useEffect(() => {
        axios
        .get(`${process.env.REACT_APP_Base_url}/products/active`)
        .then((res) => {
            //console.log(res.data);
            if (res.data.status === 200) {
            setProducts(res.data.product);
            //setIsLoading(false);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    // get cart details of the logged in user
    useEffect(() => {
        axios
        .get(`${process.env.REACT_APP_Base_url}/shoppers/get_user_cart_items`, {
            headers: {
            Authorization: `Bearer ${sessionStorage.getItem("shopper_token")}`,
            },
        })
        .then((res) => {
            //console.log(res.data);
            setCartData(res.data);
            setCartDetails(res.data.product);
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, []);

    // function to remove items from  cart
    const removeCart = (cart_id: string) => {
        axios
        .post(
            `${process.env.REACT_APP_Base_url}/products/remove_from_cart/`,
            {
            cart_id: cart_id,
            },
            {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("shopper_token")}`,
            },
            }
        )
        .then(() => {
            window.location.reload();
        })
        .catch((err) => {
            console.log(err);
        });
    };

    {/*const removeCart = async (cart_id: string) => {
    try {
        const response = await axios.post(
        `${process.env.REACT_APP_Base_url}/products/remove_from_cart/`,
        {
            cart_id: cart_id,
        },
        {
            headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        }
        );

        // Optimistic update (assuming successful removal)
        setCartData({ ...cartData, product: cartDetails.filter((item) => item.cart_id !== cart_id) });
        setCartDetails(cartDetails.filter((item) => item.cart_id !== cart_id));

        console.log("Item removed from cart successfully:", response.data);
    } catch (error) {
        console.error("Error removing item from cart:", error);
        // Display error message to user and revert local state changes (if needed)
    }
    };*/}

    return (
        <>
            <HeaderCopy />
    
            <div id="wrapper">
                <div className="container">
                    {isLoading ? (
                        <Loader loading={isLoading} />
                    ) : (
                        <div className="row">
                            <div className="col-12">
                                {cartDetails && cartDetails.length > 0 ? (
                                    <div className="shopping-cart">
                                        <div className="row">
                                            <div className="col-sm-12 col-lg-8">
                                                <div className="left">
                                                    <h1 className="cart-section-title">My Cart ({cartDetails.length})</h1>
                                                    {cartDetails.map((item: any, index: number) => (
                                                        <div className="item" key={item.cart_id}>
                                                            <div className="cart-item-image">
                                                                <div className="img-cart-product">
                                                                    <Link to={`/details`} state={{ data: item.product_id }}>
                                                                        <img 
                                                                            src={item.product_image}
                                                                            alt={item.product_name} 
                                                                            className="img-fluid img-product ls-is-cached lazyloaded" 
                                                                        />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className="cart-item-details">
                                                                <div className="list-item">
                                                                    <Link to={`/details`} state={{ data: item.product_id }}>
                                                                        {item.product_name}
                                                                    </Link>
                                                                </div>
                                                                <div className="list-item seller">
                                                                    By&nbsp;<a href="#">{item.company_name}</a>
                                                                </div>
                                                                <div className="list-item m-t-15">
                                                                    <label>Unit Price:</label>
                                                                    <strong className="lbl-price"><span>¢</span>
                                                                        {parseFloat(item.product_price).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
                                                                    </strong>
                                                                </div>
                                                                <div className="list-item">
                                                                    <label>Total:</label>
                                                                    <strong className="lbl-price"><span>¢</span>
                                                                        {item.total_cost?.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
                                                                    </strong>
                                                                </div>
                                                                <a href="javascript:void(0)" className="btn btn-sm btn-outline-gray btn-cart-remove" onClick={() => removeCart(item.cart_id)}>
                                                                    <Icon name="MdClose" style={{ marginRight: '8px', position: 'relative', top: '-1px' }} /> 
                                                                    Remove
                                                                </a>
                                                            </div>
                                                            <div className="cart-item-quantity">
                                                                <div className="number-spinner">
                                                                    <div className="input-group">
                                                                        <span className="input-group-btn">
                                                                            <button type="button" className="btn btn-default btn-spinner-minus" data-cart-item-id="66151ece1e2271-78831288-62691963" data-dir="dwn">-</button>
                                                                        </span>
                                                                        <input type="text" id="q-66151ece1e2271-78831288-62691963" className="form-control text-center" value="1" data-product-id="283" data-cart-item-id="66151ece1e2271-78831288-62691963" />
                                                                        <span className="input-group-btn">
                                                                            <button type="button" className="btn btn-default btn-spinner-plus" data-cart-item-id="66151ece1e2271-78831288-62691963" data-dir="up">+</button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>  
                                                    ))}
                                                </div>
                                                <a href="/" className="btn btn-md btn-custom m-t-15">
                                                    <Icon name="BiChevronLeft" className="m-r-2" />
                                                    Keep Shopping
                                                </a>
                                            </div>

                                            {cartData && (
                                                <div className="col-sm-12 col-lg-4">
                                                    <div className="right">
                                                        <div className="row-custom m-b-15">
                                                            <strong>Subtotal
                                                                <span className="float-right">
                                                                    ¢{cartData.cart_total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </span>
                                                            </strong>
                                                        </div>
                                                        <div className="row-custom">
                                                            <p className="line-seperator"></p>
                                                        </div>
                                                        <div className="row-custom m-b-10">
                                                            <strong>
                                                                Total
                                                                <span className="float-right">
                                                                    ¢{cartData.cart_total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </span>
                                                            </strong>
                                                        </div>
                                                        <div className="row-custom m-t-30 m-b-15">
                                                            <a href="/checkout" className="btn btn-block">Continue to Checkout</a>
                                                        </div>
                                                        {/* Payment icons */}     
                                                        <div className="payment-icons">
                                                            <img src={visa} alt="visa" />
                                                            <img src={mastercard} alt="mastercard" />
                                                            <img src={maestro} alt="maestro" />
                                                            <img src={amex} alt="amex" />
                                                            <img src={discover} alt="discover" />
                                                        </div>                  
                                                        <hr className="m-t-30 m-b-30" />
                                                        {/* Discount coupons */}
                                                        <form action="#" method="post" id="form_validate" className="m-0">
                                                            <input type="hidden" name="app_csrf_token" value="fc983c832ca8f0ef97cd895125c93372" />
                                                            <label className="font-600">Discount Coupon</label>
                                                            <div className="cart-discount-coupon">
                                                                <input type="text" name="coupon_code" className="form-control form-input" value="" maxLength={254} placeholder="Coupon Code" />
                                                                <button type="submit" className="btn btn-custom m-l-5">Apply</button>
                                                            </div>
                                                            <input type="hidden" name="sysLangId" value="1" />
                                                        </form>
                                                        <div className="cart-coupon-error"></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    !isLoading && (
                                        <div className="shopping-cart-empty">
                                            <p><strong className="font-600">Your cart is empty!</strong></p>
                                            <a href="/products" className="btn btn-lg btn-custom"><Icon name="BiChevronLeft" />&nbsp;Shop Now</a>
                                        </div>
                                    )
                                )}  
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Cart;