import React, { useState, useEffect } from 'react';
import axios from 'axios';
import visa from "../../assets/img/visa.svg";
import verve from "../../assets/img/verve.svg";
import mastercard from "../../assets/img/mastercard.svg";
import paystack from "../../assets/img/paystack.svg";

const PaystackForm = () => {
    const [cartData, setCartData] = useState<any>([]);
    //const [cartDetails, setCartDetails] = useState<any>([]);

    // fetches cart details
    useEffect(() => {
        window.scrollTo(0, 0);

        axios
        .get(`${process.env.REACT_APP_Base_url}/utils/get_user_cart_items`, {
            headers: {
            Authorization: `Bearer ${sessionStorage.getItem("shopper_token")}`,
            },
        })
        .then((res) => {
            console.log(res.data);
            setCartData(res.data);
            //setCartDetails(res.data.product);
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);
  
    return (
        <div id="paystackForm" className="tab-checkout tab-checkout-open">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <form>
                                <div id="payment-button-container" className="payment-button-cnt" style={{ visibility: 'visible' }}>
                                    <div className="payment-icons-container">
                                        <label className="payment-icons">
                                            <img src={visa} alt="visa" />
                                            <img src={mastercard} alt="mastercard" />
                                            <img src={verve} alt="verve" />
                                            <img src={paystack} alt="paystack" />
                                        </label>
                                    </div>
                                    <p className="p-complete-payment">
                                        Please click the button below to complete the payment.
                                    </p>
                                    <button type="button" className="btn btn-lg btn-payment btn-paystack">
                                        Pay&nbsp;
                                        <span>GH¢</span>
                                        {cartData.cart_total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaystackForm;