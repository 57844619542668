import { useState, useEffect } from 'react';
import { SESSION_EXPIRED_EVENT } from '../services/api'; 
import { Role, TokenRefreshError } from '../utils/tokenUtils';

export const useSessionExpiration = () => {
    const [isSessionExpired, setIsSessionExpired] = useState(false);
    const [expiredRole, setExpiredRole] = useState<Role | null>(null);
    const [errorType, setErrorType] = useState<TokenRefreshError | undefined>();
    const [isBlocked, setIsBlocked] = useState(false);

    useEffect(() => {
        // Handler for session expired event
        const handleSessionExpired = (event: CustomEvent) => {
            const { role, errorType: eventErrorType } = event.detail;

            // Handle blocked users specifically
            if (eventErrorType === TokenRefreshError.ACCOUNT_BLOCKED) {
                setIsBlocked(true);
            }

            setIsSessionExpired(true);
            setExpiredRole(role);
            setErrorType(eventErrorType);
        };

        // Add event listener
        window.addEventListener(SESSION_EXPIRED_EVENT, 
            handleSessionExpired as EventListener
        );

        // Cleanup
        return () => {
            window.removeEventListener(SESSION_EXPIRED_EVENT, 
                handleSessionExpired as EventListener
            );
        };
    }, []);

    const closeSessionExpiredModal = () => {
        setIsSessionExpired(false);
        setExpiredRole(null);
        setIsBlocked(false);
    };

    const handleLogin = () => {
        if (expiredRole) {
            sessionStorage.clear();
            window.location.href = `/${expiredRole}/login`;
        }
    };

    return {
        isSessionExpired,
        expiredRole,
        closeSessionExpiredModal,
        handleLogin,
        errorType,
        isBlocked
    };
};