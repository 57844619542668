import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PulseLoader, ClipLoader } from "react-spinners";
import axios from "axios";

// Components
import Icon from "../../../components/icon/Icon";
import ConfirmSellerButton from "../../../components/admin/ConfirmSellerButton";
import usePagination from "../../../hooks/usePagination";

// Assets
import user from "../../../assets/img/user.png";

// API services and type definition imports
import { adminService } from "../../../services/adminService";
import { messageService } from "../../../services/messageService";
import { User, UserCardProps, UsersState, PaginationResponse } from "../../../types/Types";

const UserCard: React.FC<UserCardProps> = ({
    user,
    type,
    onToggleStatus,
    onMessage,
    onClick,
    isLoading = false,
}) => {
    const isBlocked = user.status === "blocked"; // Simplify condition

    return (
        <Card onClick={onClick}>
            <CardHeader>
                <CardTitle>{user.first_name} {user.last_name}</CardTitle>
                {type === "sellers" && user.status && (
                    <Badge status={user.status}>{user.status}</Badge>
                )}
                {type === "shoppers" && user.status && (
                    <Badge status={user.status}>{user.status}</Badge>
                )}
            </CardHeader>
            
            <p>Email: {user.email}</p>
            
            {type === "sellers" && (
                <>
                    <p>Business Name: {user.company_name}</p>
                    <p>Products: {user.products ?? 0}</p>
                    <p>Revenue: {user.revenue ?? 0}</p>
                </>
            )}
            
            {type === "shoppers" && (
                <>
                    <p>Purchases: {user.purchases ?? 0}</p>
                    <p>Total Spend: {user.totalSpend ?? 0}</p>
                </>
            )}

            <CardFooter>
                <Button onClick={(e) => { e.stopPropagation(); onMessage(user); }} variant="modern">
                    <Icon name="TbMessageCircle" size="1.2em" style={{ position: "relative", top: "-2px" }} /> Message
                </Button>
                <Button
                    onClick={(e) => { e.stopPropagation(); onToggleStatus(user.id, type); }}
                    variant="red"
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <ClipLoader color="#fff" size={15} />
                    ) : (
                        <>
                            <Icon
                                name={isBlocked ? "FaUserCheck" : "FaUserTimes"}
                                size="1.2em"
                                style={{ position: "relative", top: "-2px" }}
                            /> 
                            {isBlocked ? "Unblock" : "Block"}
                        </>
                    )}
                </Button>
            </CardFooter>
        </Card>
    );
};

// Message modal
const MessageModal: React.FC<{
    user: User;
    receiverId: number;
    onClose: () => void;
    onSend: (receiverId: number, messageText: string) => void;
}> = ({ user, receiverId, onClose, onSend }) => {
    const [message, setMessage] = useState("");
    //const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const handleSend = () => {
        onSend(receiverId, message); // Pass receiverId and message to onSend
        setMessage("");
    };

    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                <h3>Message {user.first_name} {user.last_name}</h3>
                <TextArea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Write your message here"
                />
                <Button onClick={handleSend}>
                    <Icon name="FaPaperPlane" size="1.2em" style={{ position: "relative", top: "-2px" }} /> Send
                </Button>

                {/* Conditionally render the success message under the button 
                {successMessage && <div style={{ marginTop: '10px', color: 'green' }}>{successMessage}</div>}*/}

            </ModalContent>
        </ModalOverlay>
    );
};

const UserManagement: React.FC = () => {
    const [activeTab, setActiveTab] = useState<"sellers" | "shoppers">("sellers");
    const [searchTerm, setSearchTerm] = useState("");
    const [filter, setFilter] = useState<string | null>(null);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [isMessageModalOpen, setMessageModalOpen] = useState(false);
    const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);

    // Add loading and error states
    const [loadingStates, setLoadingStates] = useState<Record<string | number, boolean>>({});

    const {
        data: users,
        setData: setUsers,
        currentPage,
        totalPages,
        totalItems,
        loading,
        error,
        handlePrev,
        handleNext,
        setPage,
    } = usePagination<User>(
        (page, limit) => adminService.getUsers(activeTab, page, limit, searchTerm, filter ?? undefined ), 
        5, 
        [searchTerm, filter, activeTab]
    );

    // handles confirmation of sellers
    const confirmSeller = async (id: string | number) => {
        try {
            const updatedSeller = await adminService.confirmSeller(id);
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.id === id ? { ...user, status: "active", ...updatedSeller } : user
                )
            );
        } catch (error) {
            console.error(`Error confirming seller with ID ${id}:`, error instanceof Error ? error.message : error);
        }
    };
    
    // handles toggling user status
    const toggleUserStatus = async (id: string | number, type: "sellers" | "shoppers") => {
        const user = users.find((u) => u.id === id);
        if (!user) {
            alert("User not found.");
            return;
        }
    
        // Determine the new status
        const newStatus = user.status === "blocked" ? "active" : "blocked";

        // Optimistically update the UI immediately
        setUsers((prevUsers) => 
            prevUsers.map((u) => 
                u.id === id ? { ...u, status: newStatus } : u
            )
        );
    
        // Set loading state for this specific user
        setLoadingStates((prev) => ({ ...prev, [id]: true }));
    
        try {
            // Attempt to update the status on the server
            const updatedUser = await adminService.updateUserStatus(id, type, newStatus);
            
            // If the server returns a different status, update to the server's response
            setUsers((prevUsers) => 
                prevUsers.map((u) => 
                    u.id === id ? { ...u, ...updatedUser } : u
                )
            );
        } catch (err) {
            // If the update fails, revert the local state
            setUsers((prevUsers) =>
                prevUsers.map((u) => 
                    u.id === id ? { ...u, status: user.status } : u
                )
            );

            // Show an error message
            alert(`Failed to update user status: ${err instanceof Error ? err.message : 'Unknown error'}`);

            console.error(`Error updating status for user ID ${id}:`, err instanceof Error ? err.message : err);
        } finally {
            // Always remove the loading state
            setLoadingStates((prev) => {
                const newStates = { ...prev };
                delete newStates[id];
                return newStates;
            });
        }
    };

    // handles sending of messages
    const handleMessage = async (receiverId: number, messageText: string) => {
        if (!selectedUser || !messageText.trim()) {
            alert("Please select a user and enter a message.");
            return;
        }
    
        try {
            const response = await messageService.sendMessage(receiverId, messageText);
    
            if (response) {
                console.log("Message sent successfully:", response);
            } else {
                throw new Error("No data returned from the API.");
            }
        } catch (error) {
            console.error("Error sending message:", error instanceof Error ? error.message : error);
        }
    };
    

    // Filter users based on search term and filter
    const filteredUsers = users?.filter((user) => {
        const fullName = `${user.first_name || ""} ${user.last_name || ""}`.trim().toLowerCase();
        const matchesSearch =
          fullName.includes(searchTerm.toLowerCase()) ||
          (user.company_name?.toLowerCase() || "").includes(searchTerm.toLowerCase());
        const matchesStatus = filter ? user.status === filter : true;
    
        return matchesSearch && matchesStatus;
    });

    // handles formatting date
    const formatCreatedAt = (dateString: string): string => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }; // Format: 1 November 2024
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <Container>
            <Tabs>
                <Tab active={activeTab === "sellers"} onClick={() => setActiveTab("sellers")}>
                    Sellers
                </Tab>
                <Tab active={activeTab === "shoppers"} onClick={() => setActiveTab("shoppers")}>
                    Shoppers
                </Tab>
            </Tabs>

            {/* Search button */}
            <SearchBar>
                <Input
                    id="search_input"
                    name="search"
                    type="text"
                    placeholder={`Search ${activeTab}...`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button onClick={() => setSearchTerm("")}>Clear</Button>
            </SearchBar>

            {/* Filter */}
            <FilterBar>
                <FilterDropdown
                    id="statusFilter"
                    name="statusFilter"
                    value={filter || ""} 
                    onChange={(e) => setFilter(e.target.value || null)}>
                    <option value="">All Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="pending">Pending</option>
                    <option value="blocked">Blocked</option>
                </FilterDropdown>
                {filter && (
                    <ClearFilterButton onClick={() => setFilter(null)}>Clear Filter</ClearFilterButton>
                )}
            </FilterBar>

            {/* Errors and a retry button */}
            {error && (
                <ErrorMessage>
                    {error}
                    <Button onClick={() => setPage(currentPage)}>Retry</Button>
                </ErrorMessage>
            )}

            {/* Loading State */}
            {loading ? (
                <LoadingOverlay>
                    <PulseLoader 
                        color="#4A90E2" 
                        size={15}
                        margin={2}
                        speedMultiplier={0.8}
                    />
                    <LoadingText>Loading...</LoadingText>
                </LoadingOverlay>
            ) : (
                <>
                    {/* User List */}
                    {filteredUsers?.length > 0 ? (
                        filteredUsers.map((user) => (
                            <UserCard
                                key={user.id}
                                user={user}
                                type={activeTab}
                                onToggleStatus={toggleUserStatus}
                                onMessage={() => {
                                    setSelectedUser(user);
                                    setMessageModalOpen(true);
                                }}
                                onClick={() => {
                                    setSelectedUser(user);
                                    setDetailsModalOpen(true);
                                }}
                                isLoading={loadingStates[user.id]} // Pass the specific user's loading state
                            />
                        ))
                    ) : (
                        <p>No users found</p>
                    )}

                    {/* Pagination Controls */}
                    <PaginationControls>
                        <PaginationInfo>
                            Showing{" "}
                            <span>
                                <strong>
                                    {(currentPage - 1) * 5 + 1}&nbsp;-&nbsp;{Math.min(currentPage * 5, totalItems)}
                                </strong>
                            </span>{" "}
                            of <strong>{totalItems}</strong>{" "}
                            {activeTab === "shoppers"
                                ? totalItems === 1
                                ? "shopper"
                                    : "shoppers"
                                : totalItems === 1
                                ? "seller"
                            : "sellers"}
                        </PaginationInfo>
                        <PaginationButtonContainer>
                            <ButtonContainer>
                                <Button onClick={handlePrev} disabled={currentPage === 1}>
                                    <Icon name="BiChevronLeft" /> Prev
                                </Button>
                                <Button onClick={handleNext} disabled={currentPage === totalPages || totalPages === 0}>
                                    Next <Icon name="BiChevronRight" />
                                </Button>
                            </ButtonContainer>
                        </PaginationButtonContainer>
                    </PaginationControls>
                </>
            )}


            {/* Details Modal */}
            {isDetailsModalOpen && selectedUser && (
                <ModalOverlay onClick={() => setDetailsModalOpen(false)}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <CloseButton onClick={() => setDetailsModalOpen(false)}>&times;</CloseButton>
                        
                        <CenteredHeader>
                            <h2>{selectedUser.first_name} {selectedUser.last_name}</h2>
                            {selectedUser.status && (<Badge status={selectedUser.status}>{selectedUser.status}</Badge>)}
                        </CenteredHeader>
                        
                        {/* Profile Picture */} 
                        <ProfileImage
                            src={selectedUser.profile_picture || user}
                            alt={`${selectedUser.first_name}'s profile`}
                        />

                        <p><b>Email:</b> {selectedUser.email}</p>
                        {selectedUser.contact && ( 
                            <p>
                                <b>Contact:</b>{" "}
                                <a href={`tel:${selectedUser.contact}`} style={{ color: 'blue', textDecoration: 'none' }}>
                                    {selectedUser.contact}
                                </a> 
                            </p>
                        )}
                        {selectedUser.location && <p><b>Location:</b> {selectedUser.location}</p>}   
                        {selectedUser.company_name && <p><b>Company:</b> {selectedUser.company_name}</p>}
                        {selectedUser.business_category && (
                            <p><b>Category:</b> {selectedUser.business_category}</p>
                        )}
                        {selectedUser.shop_description && (
                            <p><b>Description:</b> {selectedUser.shop_description}</p>
                        )}
                        {selectedUser.activated !== undefined && (
                            <p><b>Activated:</b> {selectedUser.activated ? "Yes" : "No"}</p>
                        )}

                        {/* Account Creation Date */}
                        {selectedUser.created_at && (
                        <p>
                            <b>Account Created:</b> {formatCreatedAt(selectedUser.created_at)}
                        </p>
                        )}

                        {selectedUser.products && <p>Products: {selectedUser.products}</p>}
                        {selectedUser.revenue && <p>Revenue: {selectedUser.revenue}</p>}
                        {selectedUser.purchases && <p>Purchases: {selectedUser.purchases}</p>}
                        {selectedUser.totalSpend && <p>Total Spend: {selectedUser.totalSpend}</p>}

                        {/* Conditionally render the "Confirm Seller" button */}
                        {activeTab === "sellers" && selectedUser.status === "pending" && (
                            <CenteredButtonContainer>
                                <ConfirmSellerButton
                                    userId={selectedUser.id}
                                    confirmSeller={confirmSeller}
                                />
                            </CenteredButtonContainer>
                        )}
                    </ModalContent>
                </ModalOverlay>
            )}

            {/* Message Modal */}
            {isMessageModalOpen && selectedUser && (
                <MessageModal
                    user={selectedUser}
                    receiverId={Number(selectedUser.id)}
                    onClose={() => {
                        setMessageModalOpen(false);
                        setSelectedUser(null);
                    }}
                    onSend={handleMessage}
                />
            )}
            
        </Container>
    );
};

export default UserManagement;


// Styled components
const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 1rem;
`;

const Tabs = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

const Tab = styled.button<{ active: boolean }>`
    flex: 1;
    padding: 0.75rem;
    border: none;
    border-radius: 12px;
    background-color: ${(props) => (props.active ? "#f0f0f0" : "transparent")};
    color: ${(props) => (props.active ? "#333" : "#555")};
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: ${(props) => (props.active ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none")};

    &:hover {
        background-color: #f0f0f0;
    }
`;

const SearchBar = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

const Input = styled.input`
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    margin-right: 0.5rem;
`;

const Button = styled.button<{ variant?: "red" | "modern" }>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: ${(props) => (props.variant === "red" ? "none" : "1px solid #ddd")};
    border-radius: 12px;
    background-color: ${(props) =>
        props.variant === "red" ? "#dc3545" : "#f9f9f9"};
    color: ${(props) => (props.variant === "red" ? "#fff" : "#333")};
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        background-color: ${(props) =>
        props.variant === "red" ? "#c82333" : "#f0f0f0"};
    }
`;

const CenteredHeader = styled.h2`
    text-align: center;
    margin: 20px 0;
`;

const Card = styled.div`
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;

    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    text-align: center;
    gap: 0.5rem; /* Space between the title and badge */
`;

const CardTitle = styled.h3`
    font-size: 1rem;
    margin: 0;
`;

const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
`;

const Badge = styled.span<{ status: string }>`
    padding: 0.25rem 0.5rem;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: bold;
    color: ${(props) =>
        props.status === "active"
        ? "#28a745"
        : props.status === "pending"
        ? "#ffc107"
        : "#dc3545"};
    background-color: ${(props) =>
        props.status === "active"
        ? "#d4edda"
        : props.status === "pending"
        ? "#fff3cd"
        : "#f8d7da"};
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    position: relative;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
`;

const CenteredButtonContainer = styled.div`
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;    /* Center vertically if needed */
    margin-top: 1.5rem;     /* Add spacing above the button */
`;

// Add loading and error styled components
const LoadingOverlay = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    gap: 1rem;
`;

const LoadingText = styled.p`
    color: #666;
    margin-top: 0.5rem;
    font-size: 0.875rem;
`;

const ErrorMessage = styled.div`
    color: #dc3545;
    padding: 1rem;
    margin: 1rem 0;
    background-color: #f8d7da;
    border-radius: 4px;
    text-align: center;
`;

const ProfileImage = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin: 10px auto;
    display: block;
`;

// Styled filter bar
const FilterBar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
`;

const FilterDropdown = styled.select`
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    background: #fff;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        border-color: #aaa;
    }
`;

const ClearFilterButton = styled(Button)`
    background: #f0f0f0;
    color: #333;

    &:hover {
        background: #ddd;
    }
`;

const PaginationInfo = styled.div`
  text-align: left;
  flex: 1;
  font-weight: normal;

  span strong {
    font-weight: bold;
  }
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
`;

const PaginationButtonContainer = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex; /* Keep buttons visible all the time */
  gap: 10px;

  button {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 8px 16px;
    border: 1px solid transparent; /* Add default border */
    background-color: #f1f1f1;
    cursor: pointer;

    /* Hover effect */
    &:hover {
      border: 1px solid #4a90e2; /* Border appears on hover */
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      border: 1px solid #dcdcdc; /* Disabled state border */
    }
  }
`;