import axios, { AxiosInstance, AxiosError } from "axios";
import { handleTokenExpiration, getToken } from "../utils/tokenUtils";

// Create a custom event for session expiration
export const SESSION_EXPIRED_EVENT = 'session-expired';

// Create the configured axios instance
const api: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_Base_url,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Create a separate axios instance for handling login, logout, and token refresh
const authApi: AxiosInstance = axios.create({
    baseURL: '/api/',
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,  // Include credentials with requests
});

// Add request interceptor
api.interceptors.request.use(
    async (config) => {
        const role = sessionStorage.getItem("role") as "shopper" | "seller" | "super-admin" | null;

        if (!role) {
            throw new Error("Invalid or missing role");
        }

        try {
            await handleTokenExpiration(role);

            const token = getToken(role);
            if (!token) throw new Error("No token found after refresh");

            config.headers['Authorization'] = `Bearer ${token}`;
            return config;
        } catch (error) {
            console.error("Request Interceptor Error:", error);
            // Dispatch custom event instead of direct redirection
            window.dispatchEvent(new CustomEvent(SESSION_EXPIRED_EVENT, { 
                detail: { role }
            }));
            throw error;
        }
    },
    (error) => Promise.reject(error)
);

// Response interceptor to handle 401 errors
api.interceptors.response.use(
    (response) => response, // Pass successful responses
    async (error: AxiosError) => {
        const originalRequest = error.config; // The request that caused the error
        const role = sessionStorage.getItem("role") as "shopper" | "seller" | "super-admin" | null;

        if (error.response?.status === 401 && role) { // Handle unauthorized errors
            try {

                if (!role) {
                    throw new Error("Invalid or missing role");
                }

                // Handle token expiration and retry once
                await handleTokenExpiration(role); 

                if (!originalRequest) {
                    throw new Error("Original request configuration is missing");
                }

                return await axios.request(originalRequest);  // Retry the request
            } catch (refreshError) {
                console.error("Token refresh failed:", refreshError);
                // Dispatch custom event for session expiration
                window.dispatchEvent(new CustomEvent(SESSION_EXPIRED_EVENT, { 
                    detail: { role }
                }));
            }
        }

        
        // Handle blocked users (403 Forbidden)
        if (error.response?.status === 403 && role) {
            console.warn("User is blocked. Triggering session expiration.");
            window.dispatchEvent(new CustomEvent(SESSION_EXPIRED_EVENT, { 
                detail: { role }
            }));
        }

        return Promise.reject(error); // Reject for other errors
    }
);

export { api, authApi };