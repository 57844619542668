import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProductProps } from "../../types/Types";

// Components
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import Loader from "../../components/loader/Loader";
import WishlistHeartBeat from "../../components/icon/WishlistHeartBeat";
import WishlistButton from "../../components/wishlist button/WishlistButton";
import WishlistTotal from "../../components/wishlist button/WishlistTotal";
import Stars from '../../components/stars/Stars';
import Icon from "../../components/icon/Icon";

const Wishlist = () => {
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1); // Track current page
    const [limit] = useState(10); // Products per page (can be made dynamic if needed)
    const [totalPages, setTotalPages] = useState(1); // Total number of pages
    const [search, setSearch] = useState(''); // Search query
    const [searchTerm, setSearchTerm] = useState(''); // Controlled input for search

    // Fetch wishlist products whenever page, limit, or search changes
    const fetchWishlistProducts = async () => {
            
        try {
            const userSessionId = localStorage.getItem('userSessionId');
            if (userSessionId) {
                const response = await axios.get(
                    `${process.env.REACT_APP_Base_url}/shoppers/wishlist/${userSessionId}`,
                    {
                        params: {
                            page,
                            limit,
                            search
                        }
                    }
                );
                    
                const { products, totalPages } = response.data; // Destructure the response
                    setProducts(products);
                    setTotalPages(totalPages);
                } else {
                    console.error('No user session ID found');
                }
            } catch (error) {
                console.error('Error fetching wishlist products:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        setIsLoading(true);
        fetchWishlistProducts();
    }, [page, limit, search]); // Re-run on page, limit, or search change

    // Handle user input for search term
    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        setSearch(newSearchTerm); // Update search state to trigger API call immediately
        setPage(1); // Reset to page 1 when search term changes
    };

    // Handle pagination
    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const handlePreviousPage = () => {
        if (page > 1) setPage(page - 1);
    };

    // Function to format price
    const formatPrice = (price: number) => {
        // Format the number to two decimal places
        const formattedPrice = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(price);

        // Prepend the cedi symbol
        return `¢${formattedPrice}`;
    };

    return (
        <>
            <HeaderCopy />
      
            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="nav-breadcrumb" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Wishlist</li>
                                </ol>
                            </nav>
                            <h1 className="page-title">Wishlist&nbsp;<WishlistHeartBeat /></h1>  
                            <div className="row">         
                                <div className="col-12 col-md-3 col-sidebar-products">
                                    <div className="filter-item" style={{ paddingBottom: '20px' }}>
                                        <div className="profile-search">
                                            <input 
                                                type="search" 
                                                name="search" 
                                                id="input_search_wishlist_product" 
                                                className="form-control form-input profile-search" 
                                                placeholder="Search"
                                                value={searchTerm} // Bind state to the input value
                                                onChange={handleSearchInputChange} // Update state on input change 
                                            />
                                            <button 
                                                id="btn_search_product" 
                                                className="btn btn-default btn-search"
                                                onClick={() => setSearch(searchTerm)} // Trigger search on button click
                                            >
                                                <Icon name="CiSearch" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="col-12">
                            <div className="page-contact">
                                {isLoading ? (
                                    <Loader loading={isLoading} />
                                ) : (
                                    <div className="row">
                                        {products.length === 0 ? (
                                            <div className="col-12">
                                                <p className="text-center">No products found!</p>      
                                            </div>
                                        ) : (
                                            products.map((product: ProductProps) => {
                                                return (
                                                    <div className="col-6 col-sm-4 col-md-3 col-mds-5 col-product" key={product.id}>
                                                        <div className="product-item">                        
                                                            <div className="row-custom product-multiple-image">
                                                                <div className="img-product-container">
                                                                    <Link to={`/details`} state={{ data: product.id }}> 
                                                                        <img
                                                                            src={product.main_image}
                                                                            alt={product.product_name}
                                                                            className="img-fluid img-product ls-is-cached lazyloaded"
                                                                            loading="lazy"
                                                                        />
                                                                        <div className="thumbnail hover">
                                                                            <img
                                                                                src={product.product_images[0]}
                                                                                alt={product.product_name}
                                                                                className="img-fluid img-product img-second ls-is-cached lazyloaded"
                                                                                loading="lazy"
                                                                            />
                                                                        </div>
                                                                    </Link>
                                                                    <div className="actions">
                                                                        <ul>
                                                                            <li>
                                                                                <WishlistButton productId={product.id} />
                                                                            </li>
                                                                            <li>
                                                                                <a href="/cart" className="tooltip-container">
                                                                                    <Icon name="IoCartOutline" size="1.5em" /> 
                                                                                    <div className="tooltip">
                                                                                        <span className="tooltip-text">Add to Cart</span>
                                                                                        <span className="tooltip-arrow"></span>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <Link to={`/details`} state={{ data: product.id }}>
                                                                                    <a href="/details" className="tooltip-container">
                                                                                        <Icon name="FaEye" size="1.5em" />  
                                                                                        <div className="tooltip">
                                                                                            <span className="tooltip-text">View Details</span>
                                                                                            <span className="tooltip-arrow"></span>
                                                                                        </div>
                                                                                    </a>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="sub-actions">
                                                                        <ul>
                                                                            <li>
                                                                                <a className="item-option btn-add-remove-wishlist">
                                                                                    <WishlistButton productId={product.id} />
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="/cart" className="item-option tooltip-container">
                                                                                    <Icon name="IoCartOutline" size="1.5em" /> 
                                                                                    <div className="tooltip">
                                                                                        <span className="tooltip-text">Add to Cart</span>
                                                                                        <span className="tooltip-arrow"></span>
                                                                                    </div>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row-custom item-details">
                                                                <h3 className="product-title">
                                                                    <Link to={`/details`} state={{ data: product.id }}>
                                                                        {product.product_name}
                                                                    </Link>
                                                                </h3>
                                                                <p className="product-user text-truncate">
                                                                    <a href="/profile">{product.company_name}</a>
                                                                </p>
                                                                <div className="product-item-rating">
                                                                    <div className="rating">
                                                                        <Stars productId={product.id} />
                                                                    </div>
                                                                    <WishlistTotal productId={product.id} />
                                                                </div>
                                                                <Link to={`/details`} state={{ data: product.id }}>
                                                                    <div className="item-meta">
                                                                        <span className="price">{formatPrice(product.product_price)}</span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>                       
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* Pagination */}
                        {products.length > 0 ? (
                            <div className="col-12">
                                <div className="row">
                                    <div className="pagination-buttons-container">
                                        <button
                                            className="pagination-button"
                                            onClick={handlePreviousPage} 
                                            disabled={page === 1 || isLoading}
                                        >
                                            <Icon name="BiChevronLeft" /> Prev
                                        </button>
                                        <span>Page {page} of {totalPages}</span>
                                        <button
                                            className="pagination-button"
                                            onClick={handleNextPage} 
                                            disabled={page === totalPages}
                                        >
                                            Next <Icon name="BiChevronRight" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div> 

            <Footer />
        </>
    );
};

export default Wishlist;