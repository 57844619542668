import React, { useEffect, useState } from "react";

const Orders: React.FC = () => {
  const [products, setProducts] = useState<any[]>([]);

  useEffect(() => {
    // Fetch products data (API simulation)
    setTimeout(() => {
      setProducts([
        { id: 1, name: "Product A", price: "$20", stock: 50 },
        { id: 2, name: "Product B", price: "$30", stock: 30 },
      ]);
    }, 500);
  }, []);

  return (
    <div className="container mt-5 pt-4">
      <h1>Reports</h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td>{product.id}</td>
              <td>{product.name}</td>
              <td>{product.price}</td>
              <td>{product.stock}</td>
              <td>
                <button className="btn btn-sm btn-warning">Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Orders;