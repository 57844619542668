import styled from 'styled-components';
import React, { useEffect } from 'react';
import { TokenRefreshError } from '../../utils/tokenUtils';

interface SessionExpiredModalProps {
    isOpen: boolean;
    onClose: () => void;
    onLogin: () => void;
    role: "shopper" | "seller" | "super-admin" | null;
    errorType?: TokenRefreshError;
}

export const SessionExpiredModal: React.FC<SessionExpiredModalProps> = ({ 
    isOpen, 
    onClose, 
    onLogin,
    role,
    errorType 
}) => {
    // Determine the appropriate error message
    const getErrorMessage = () => {
        switch (errorType) {
            case TokenRefreshError.ACCOUNT_BLOCKED:
                return "Your account has been blocked. Please contact support.";
            case TokenRefreshError.NO_TOKEN:
                return "No authentication token found. Please log in again.";
            case TokenRefreshError.NETWORK_ERROR:
                return "Network error occurred. Unable to refresh your session.";
            case TokenRefreshError.INVALID_RESPONSE:
                return "Invalid authentication response. Please log in again.";
            case TokenRefreshError.SERVER_ERROR:
                return "Server authentication error. Please try again later.";
            case TokenRefreshError.REFRESH_TOKEN_EXPIRED:
                return "Your session has expired. Please log in again.";
            default:
                return "Your session has expired. Please log in again.";
        }
    };

    
    // Handle logout for blocked users
    useEffect(() => {
        if (errorType === TokenRefreshError.ACCOUNT_BLOCKED) {
            sessionStorage.clear(); // Clear session storage
        }
    }, [errorType]);

    if (!isOpen) return null;

    return (
        <ModalOverlay>
            <ModalContainer>
                <ModalHeader>{errorType === TokenRefreshError.ACCOUNT_BLOCKED ? "Account Blocked" : "Session Expired"}</ModalHeader>
                    <ModalDescription>
                        {getErrorMessage()}
                    </ModalDescription>
                    <LoginButton onClick={errorType === TokenRefreshError.ACCOUNT_BLOCKED ? onClose : onLogin}>
                        {errorType === TokenRefreshError.ACCOUNT_BLOCKED ? "Close" : "Log In"}
                    </LoginButton>
            </ModalContainer>
        </ModalOverlay>
    );
};

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const ModalContainer = styled.div`
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalHeader = styled.h2`
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 16px;
    color: #333;
`;

const ModalDescription = styled.p`
    color: #666;
    margin-bottom: 24px;
`;

const LoginButton = styled.button`
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;