import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../icon/Icon";


const Cart = () => {
    const [cartDetails, setCartDetails] = useState<any>([]);
    const location_data = useLocation();
    const navigate = useNavigate();

    const handleJwtExpired = () => {
        alert('Your session has expired. Please log in again.');
        sessionStorage.clear();
        navigate("/login?message=Your session has expired. Please log in again.");
    };

    // get cart details of the logged in user
    useEffect(() => {
        const token = sessionStorage.getItem("shopper_token");

        // Only make the API call if the token exists
        if (token) {
            axios
            .get(`${process.env.REACT_APP_Base_url}/shoppers/get_user_cart_items`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setCartDetails(res.data);
            
                // Check if JWT expired
                if (res.status === 401) {
                    handleJwtExpired();
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }, []);

    return (
        <>
            {cartDetails && cartDetails.message === "Product retrieved successfully" ? (
                <>
                    <Link to="/cart" className="a-mobile-cart">
                        <Icon name="IoCart" size="1.825rem" />{" "}
                        <span className="notification span_cart_product_count">{cartDetails.count}</span>
                    </Link>        
                </>
            ) : (
                <>   
                    <Link to="/cart" className="a-mobile-cart">
                        <Icon name="IoCartOutline" size="1.825rem" />
                        <span className="notification span_cart_product_count">0</span>       
                    </Link>    
                </>
            )}
        </>
    );
};

export default Cart;