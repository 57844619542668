import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../icon/Icon";

const Cart = () => {
    const [cartDetails, setCartDetails] = useState<any>([]);
    const location_data = useLocation();
    const navigate = useNavigate();

    const handleJwtExpired = () => {
        alert('Your session has expired. Please log in again.');
        sessionStorage.clear();
        navigate("/login?message=Your session has expired. Please log in again.");
    };

    // get cart details of the logged in user
    useEffect(() => {
        const token = sessionStorage.getItem("shopper_token");

        // Only make the API call if the token exists
        if (token) {
            axios
            .get(`${process.env.REACT_APP_Base_url}/shoppers/get_user_cart_items`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setCartDetails(res.data);
            
                // Check if JWT expired
                if (res.status === 401) {
                    handleJwtExpired();
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }, []);

    return (
        <>
            <li className="nav-item nav-item-cart li-main-nav-right">
                {cartDetails && cartDetails.message === "Product retrieved successfully" ? (
                    <>
                        <Link to="/cart">
                            <Icon name="IoCart" size="1.825rem" style={{ float: 'left', position: 'relative', marginRight: '5px', color: '#666', animation: 'slideInFromLeft 2s ease-in-out' }} />{" "}
                            <span className="notification span_cart_product_count visibility-visible">{cartDetails.count}</span>
                            <span className="label-nav-icon">
                                {" "}
                                ¢{cartDetails.cart_total?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </span>
                        </Link>        
                    </>
                ) : (
                    <>
                        <Link to="/cart">
                            <Icon name="IoCartOutline" size="1.825rem" style={{ float: 'left', position: 'relative', marginRight: '5px', color: '#666', animation: 'slideInFromLeft 2s ease-in-out'  }} />
                            <span className="notification span_cart_product_count visibility-visible">0</span>      
                            <span className="label-nav-icon">Cart: ¢0.00</span>        
                        </Link>    
                    </>
                )}
            </li>
        </>
    );
};

export default Cart;