import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SessionExpiredModal } from './components/modals/LogoutModal';
import { useSessionExpiration } from './hooks/useSessionExpiration';

// Styles       
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'swiper/swiper-bundle.min.css';
import "./style-1.1.min.css";
import "./style-1.0.min.css";

// Prime React libraries theme
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

// Shopper registration pages
import Register from "./pages/register/Register";
import Login from "./pages/login/Login";
import Activation from "./pages/activation/Activation";
import ProfileCompletion from "./pages/complete-profile/ProfileCompletion";
import Cart from "./pages/cart/Cart";
import Checkout from "./pages/checkout/Checkout";
import Dashboard from "./pages/dashboard/Dashboard";


// Product pages
import Products from "./pages/products/Products";
import ProductByCategory from "./pages/product-category/ProductByCategory";
import ProductBySection from "./pages/product-section/ProductBySection";
import Category from "./pages/category/Category";
import InactiveProductList from "./pages/product-inactive/InactiveProductList";
import ActiveProductList from "./pages/product-active/ActiveProductList";
import EditProduct from "./pages/edit-product/EditProduct";
import Wishlist from "./pages/wishlist/Wishlist";
import Details from "./pages/details/Details";
import Search from "./pages/search/Search";

// Other pages
import Terms from "./pages/terms/Terms";
import About from "./pages/about/About";
import Privacy from "./pages/privacy/Privacy";
import Help from "./pages/help/Help";
import Contact from "./pages/contact/Contact";
import Payment from "./pages/payment/Payment";
import Orders from "./pages/orders/Orders";
import Donate from "./pages/donate/Donate";
import Shops from "./pages/shops/Shops";
import AccessDenied from "./pages/access-denied/AccessDenied";

// Admin pages
import AdminLogin from "./pages/super-admin/login/AdminLogin";
import AdminLayout from "./pages/super-admin/AdminLayout";
import AdminDashboard from "./pages/super-admin/dashboard/AdminDashboard";
import UserManagement from "./pages/super-admin/users/UserManagement";
import ProductManagement from "./pages/super-admin/products/ProductManagement";
import OrderManagement from "./pages/super-admin/orders/OrderManagement";
import ReportManagement from "./pages/super-admin/reports/ReportManagement";
import AdminMessages from "./pages/super-admin/messages/AdminMessages";

// Seller pages
import SellerDashboard from "./pages/seller-dashboard/SellerDashboard";
import SellerLogin from "./pages/seller-login/SellerLogin";
import Settings from "./pages/settings/Settings";
import Profile from "./pages/profile/Profile";
import SellerProfile from "./pages/seller-profile/SellerProfile";
import ChangePassword from "./pages/change-password/ChangePassword";

// Protected route
import ProtectedRoute from "./ProtectedRoute";

// Components
import ScrollUpButton from './components/slider/ScrollUpButton';
import Newsletter from './components/newsletter/Newsletter';

// Route Configuration
const routes = [
    { path: "/", element: <Dashboard /> },
    { path: "/products", element: <Products /> },
    { path: "/shops", element: <Shops /> },
    { path: "/login", element: <Login /> },
    { path: "/search", element: <Search /> },
    { path: "/details", element: <Details /> },
    { path: "/register", element: <Register /> },
    { path: "/activation/:type", element: <Activation /> }, // Dynamic route for activation (shoppers or sellers)
    { path: "/super-admin/login", element: <AdminLogin /> },
    { path: "/product-category", element: <ProductByCategory /> },
    { path: "/product-section", element: <ProductBySection /> },
    { path: "/admin-dashboard/*", element: <AdminDashboard /> },
    { path: "/seller-dashboard/*", element: <SellerDashboard /> },
    { path: "/product-inactive", element: <InactiveProductList /> },
    { path: "/product-active", element: <ActiveProductList /> },
    { path: "/edit-product/:productId", element: <EditProduct /> },
    { path: "/terms", element: <Terms /> },
    { path: "/about", element: <About /> },
    { path: "/privacy", element: <Privacy /> },
    { path: "/help", element: <Help /> },
    { path: "/contact", element: <Contact /> },
    { path: "/payment", element: <Payment /> },
    { path: "/orders", element: <Orders /> },
    { path: "/wishlist", element: <Wishlist /> },
    { path: "/settings", element: <Settings /> },
    { path: "/profile/*", element: <Profile /> },
    { path: "/seller-profile/*", element: <SellerProfile /> },
    { path: "/donate", element: <Donate /> },
    { path: "/change-password", element: <ChangePassword /> },
    { path: "/shop", element: <Shops /> },
    { path: "/category", element: <Category /> },
    { path: "/complete-profile", element: <ProfileCompletion /> },
    { path: "/access-denied", element: <AccessDenied /> },
    { path: "/seller-login", element: <SellerLogin /> },
];

function App() {
    const { 
        isSessionExpired, 
        expiredRole, 
        closeSessionExpiredModal,
        handleLogin,
        errorType  // New error type from hook
    } = useSessionExpiration();

    return (
        <>
            <Router>
                <ScrollUpButton />
                <Routes>
                    {routes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element} />
                    ))}
                    {/* Protected routes for shoppers */}
                    <Route element={<ProtectedRoute requiredRole="shopper" isVerifiedCheck />}>
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/checkout" element={<Checkout />} />
                    </Route>

                    {/* Protected routes for sellers */}
                    <Route element={<ProtectedRoute requiredRole="seller" isVerifiedCheck />}>
                        <Route path="/seller-dashboard/*" element={<SellerDashboard />} />
                    </Route>

                    {/* Protected routes for admins */}
                    <Route path="/super-admin/*" element={<ProtectedRoute requiredRole="super-admin" />}>
                        <Route element={<AdminLayout />}>
                            {/* Nested Routes */}
                            <Route path="dashboard" element={<AdminDashboard />} />
                            <Route path="users" element={<UserManagement />} />
                            <Route path="products" element={<ProductManagement />} />
                            <Route path="orders" element={<OrderManagement />} />
                            <Route path="reports" element={<ReportManagement />} />
                            <Route path="messages" element={<AdminMessages />} />
                        </Route>
                    </Route>

                    {/* Catch-All Route */}
                    <Route path="*" element={<h1 style={{ textAlign: "center" }}>Page Not Found</h1>} />
                </Routes>
                <Newsletter />
            </Router>

            {/* Modal will be rendered over everything */}
            <SessionExpiredModal 
                isOpen={isSessionExpired}
                onClose={closeSessionExpiredModal}
                onLogin={handleLogin}
                role={expiredRole}
                errorType={errorType}  // Pass error type
            />
        </>
    );
}

export default App;
