import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import { ProductDetailsProps } from "../../types/Types";
import RelateProducts from "../../components/related products/RelateProducts";
import RelateProductsCopy from "../../components/related products/RelateProductsCopy";
import Reviews from "../../components/review/Reviews";
import AddReview from "../../components/review/AddReview";
import ProductSizes from "./ProductSizes";
import StarRating from '../../components/rating/StarRating';
import WishlistTotal from "../../components/wishlist button/WishlistTotal";
import WishlistButtonDetailsPage from "../../components/wishlist button/WishlistButtonDetailsPage";
import ShopDetails from '../../components/shop details/ShopDetails';
import { v4 as uuidv4 } from 'uuid';
import { FadeLoader } from "react-spinners";
import { FaSpinner } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import IconDetailsPage from "../../components/icon/IconDetailsPage";
import Share from "../../components/share/Share";
import ReportProductModal from '../../components/modals/ReportProductModal';


const Details = () => {
    const location = useLocation();
    const { data } = location.state || {};
    const [quantity, setQuantity] = useState<number | any>(1);
    const [product_details, setProductDetails] = useState<ProductDetailsProps | null>(null);
    const [loading, setLoading] = useState(false);
    const [addedToCart, setAddedToCart] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [averageRating, setAverageRating] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [viewCount, setViewCount] = useState(0);
    const productId = product_details?.id;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!data) {
            console.error("No product ID provided!");
            setIsLoading(false); // Ensure loading is stopped if no data
            return; // Don't proceed with the API request
        }
    
        window.scrollTo(0, 0);
    
        axios
            .get(`${process.env.REACT_APP_Base_url}/products/get_product_detail`, {
                params: {
                    productId: parseInt(data, 10), // Ensure productId is an integer
                },
            })
            .then((res) => {
                setProductDetails(res.data.product);
                if (res.data.product && res.data.product.seller_id) {
                    sessionStorage.setItem('seller_id', res.data.product.seller_id);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    
        // Cleanup function to clear seller_id from session storage on unmount
        return () => {
            sessionStorage.removeItem('seller_id');
        };
    }, [data]);
    

    // handles wishlist items
    const getUserSessionId = () => {
        let userSessionId = localStorage.getItem('userSessionId');
        if (!userSessionId) {
        userSessionId = uuidv4();
        localStorage.setItem('userSessionId', userSessionId);
        }
        return userSessionId;
    };

    // handles reviews
    const fetchReviews = async (productId: string) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_Base_url}/products/get_reviews?productId=${productId}`);
            setReviews(res.data.reviews);
            setAverageRating(res.data.averageRating);
        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    };

    // handles product views
    const fetchViewCount = async (productId: string) => {
        try {
        const res = await axios.get(`${process.env.REACT_APP_Base_url}/products/views/${productId}`);
        setViewCount(res.data.viewCount);
        } catch (error) {
        console.error('Error fetching view count:', error);
        }
    };

    const incrementViewCount = async (productId: string) => {
            const userSessionId = getUserSessionId();
        try {
        await axios.post(`${process.env.REACT_APP_Base_url}/products/views`, { productId, userSessionId });
        fetchViewCount(productId); // Refresh the view count after incrementing
        } catch (error) {
        console.error('Error incrementing view count:', error);
        }
    };

    useEffect(() => {
        if (productId) {
            fetchReviews(productId);
            fetchViewCount(productId);
            incrementViewCount(productId);
        }
    }, [productId]);
	
    // handles product mode
    const getColorForProductMode = (mode: string): string => {
        switch (mode) {
        case "In Stock":
            return "green";
        case "On Preorder":
            return "orange";
        case "Out of Stock":
            return "red";
        default:
            return "black"; // Default color if mode is not recognized
        }
    };

    // function to format currency of products
    const formattedPrice = product_details ? 
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GHS', // Change 'USD' to 'GHS' for Ghanaian cedis
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(product_details.product_price) : '';

    // Function to handle increasing quantity for an item already in the cart
    const increaseQuantity = (id: string) => {
        const newQuantity = quantity + 1; // Increase the quantity by 1
        setQuantity(newQuantity); // Update the local state with the new quantity
        handleUpdateCart(id, newQuantity); // Call the function to update the cart with the new quantity
    };

    // Function to handle decreasing quantity for an item already in the cart
    const decreaseQuantity = (id: string) => {
        const newQuantity = quantity > 1 ? quantity - 1 : 1; // Decrease the quantity by 1, but keep it at least 1
        setQuantity(newQuantity); // Update the local state with the new quantity
        handleUpdateCart(id, newQuantity); // Call the function to update the cart with the new quantity
    };

    // handles cart update
    const handleUpdateCart = (id: string, quantity: number) => {
        if (sessionStorage.getItem("token")) {
        setLoading(true);
        axios
        .post(
            `${process.env.REACT_APP_Base_url}/products/update_cart_quantity`,
            {
            product_id: id,
            cart_quantity: quantity,
            },
            {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            }
        )
        .then((res) => {
            //console.log(res.data);
            if (
            res.data.message === "jwt malformed" ||
            res.data.message === "invalid token"
            ) {
            window.location.href = "/login";
            return;
            }
            if (res.data.status === 200) {
            console.log("Cart quantity updated successfully");
            // You can add additional logic here, such as updating UI or showing a notification
            }
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
        } else {
        alert("Please login to update cart");
        window.location.href = "/login";
        }
    };

    // handle the function of adding an item cart
    const handleCart = (id: string, event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (sessionStorage.getItem("token")) {
        // Validate that quantity is a positive integer
        const parsedQuantity = parseInt(quantity, 10);

        if (!isNaN(parsedQuantity) && parsedQuantity > 0) {
        setLoading(true);
        axios
            .post(
            `${process.env.REACT_APP_Base_url}/products/add_to_cart`,
            {
                product_id: id,
                cart_quantity: parsedQuantity, // Use the parsed quantity
            },
            {
                headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            }
            )
            .then((res) => {
            //console.log(res.data);
            if (
                res.data.message === "jwt malformed" ||
                res.data.message === "invalid token"
            ) {
                window.location.href = "/login";
                return;
            }
            //alert(res.data.message);
            if (res.data.status === 200) {
                setAddedToCart(true);
            }
            window.location.reload();
            })
            .catch((err) => {
            console.log(err);
            })
            .finally(() => {
            setLoading(false);
            });
        } else {
        alert("Please enter a valid quantity (a positive integer).");
        }
    }  else {
        alert("Please login to add item to cart");
        window.location.href = "/login";
        }
    };

    if (isLoading) {
        return <FadeLoader color="#008b8b" loading={isLoading} />;
    }

    return (
        <>
            <HeaderCopy />
            
      
            {/* Product Details Section Begin */}
            {product_details && (
                <div id="wrapper">
                    <div className="container">
                    
                            <div className="row">
                            
                                {/* Product breadcrumb start */}
                                <div className="col-12">
                                    <nav className="nav-breadcrumb" aria-label="breadcrumb">
                                        <ol className="breadcrumb breadcrumb-products">
                                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                                            <li className="breadcrumb-item"><a href="/product-section">{product_details.product_section}</a></li>
                                            <li className="breadcrumb-item"><a href="/product-category">{product_details.product_category}</a></li>
                                            <li className="breadcrumb-item"><a href="#">Mobile Phones</a></li>
                                            <li className="breadcrumb-item active">{product_details.product_name}</li>
                                        </ol>
                                    </nav>
                                </div>
                                {/* Product breadcrumb end */}
                                
                                <div className="col-12">
                                    {/* Product image container and details start */}
                                    <div className="product-details-container">
                                        <div className="row">
                                                            
                                            {/* Product image container start */}
                                            <div className="col-12 col-sm-12 col-md-6">
                                                <div id="product_slider_container">
                                                    <div className="product-slider-container">
                                                        <div className="left">

                                                                            
                                                            <div className="product-slider-content">
                                                                <div id="product_thumbnails_slider" className="product-thumbnails-slider slick-initialized slick-slider slick-vertical">
                                                                    <div className="slick-list draggable" style={{ height: 504 }}>
                                                                        <div className="slick-track" style={{ opacity: '1', height: 216, transform: 'translate3d(0px, 0px, 0px)' }}>                  
                                                                            <div className="item slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" tabIndex={0} style={{ width: 67 }}>
                                                                                <div className="item-inner">
                                                                                    <img src={product_details.product_image} className="img-bg" alt="slider-bg" />
                                                                                    <img src={product_details.product_image} className="img-thumbnail" alt={product_details.product_name} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="item slick-slide slick-active" data-slick-index="1" aria-hidden="false" tabIndex={0} style={{ width: '67px' }}>
                                                                                <div className="item-inner">
                                                                                    <img src={product_details.product_image} className="img-bg" alt="slider-bg" />
                                                                                    <img src={product_details.product_image} className="img-thumbnail" alt={product_details.product_name} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="item slick-slide slick-active" data-slick-index="2" aria-hidden="false" tabIndex={0} style={{ width: '67px' }}>
                                                                                <div className="item-inner">
                                                                                    <img src={product_details.product_image} className="img-bg" alt="slider-bg" />
                                                                                    <img src={product_details.product_image} className="img-thumbnail" alt={product_details.product_name} />
                                                                                </div>
                                                                            </div>      
                                                                        </div>
                                                                    </div>                           
                                                                </div>
                                                            </div>
                                                        </div>
                                        
                                                        <div className="right">
                                                            <div className="product-slider-content">
                                                                <div id="product_slider" className="product-slider gallery slick-initialized slick-slider">
                                                                    <div className="slick-list draggable">
                                                                        <div className="slick-track" style={{ opacity: '1', width: '1473px' }}>
                                                                            <div className="item slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" tabIndex={0} style={{ width: '491', position: 'relative', left: '0px', top: '0px', zIndex: '999', opacity: '1' }}>
                                                                            <a href={product_details.product_image} title="" tabIndex={0}>
                                                                        <img src={product_details.product_image} className="img-bg" alt="slider-bg" />
                                                                    <img src={product_details.product_image} alt={product_details.product_name} className="img-product-slider" />
                                                                    </a>
                                                                </div>
                                                                                                {/*<div className="item slick-slide" data-slick-index="1" aria-hidden="true" tabIndex={-1} style={{ width: 491, position: 'relative', left: '-491px', top: '0px', zIndex: '998', opacity: '0' }}>
                                                                    <a href="#" title="" tabIndex={-1}>
                                                                    <img src={product_details.product_image} className="img-bg" alt="slider-bg" />
                                                                    <img src={product_details.product_image} alt={product_details.product_name} className="img-product-slider" />
                                                                    </a>
                                                                </div>
                                                                <div className="item slick-slide" data-slick-index="2" aria-hidden="true" tabIndex={-1} style={{ width: 491, position: 'relative', left: '-982px', top: '0px', zIndex: '998', opacity: '0' }}>
                                                                    <a href="#" title="" tabIndex={-1}>
                                                                    <img src={product_details.product_image} className="img-bg" alt="slider-bg" />
                                                                    <img src={product_details.product_image} alt={product_details.product_name} className="img-product-slider" />
                                                                    </a>
                                                                </div>*/}            
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="product-slider-nav" className="product-slider-nav">
                                                        <button className="prev slick-arrow" aria-disabled="false">
                                                            <i className="mdi mdi-chevron-left"></i>
                                                        </button>
                                                        <button className="next slick-arrow" aria-disabled="false">
                                                            <i className="mdi mdi-chevron-right"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row-custom text-center"></div>
                                            </div>
                                        </div>
                                        <div className="product-thumbnails-slider slick-initialized slick-slider slick-vertical" style={{ transform: 'none !important' }}></div>
                                    </div>
                                </div>
                                {/* Product image container end */}

                                                            {/* Product details start */}
                                        <div className="col-12 col-sm-12 col-md-6">
                                            <div id="response_product_details" className="product-content-details">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h1 className="product-title">{product_details.product_name}</h1>
                                                        <div className="row-custom meta">
                                                            <div className="product-details-user">
                                                                By&nbsp;<a href="/profile">{product_details.company_name}</a>
                                                            </div>
                                                                                <span><i className={reviews.length > 0 ? "mdi mdi-comment" : "mdi mdi-comment-outline"}></i>{reviews.length}</span> {/* Always display the count */}
                                                            <div className="product-details-review">
                                                                <div className="rating">
                                                                                        <StarRating rating={averageRating} onRatingChange={() => {}} />
                                                                </div>
                                                                                    <span>({reviews.length})</span>
                                                            </div>
                                                            <WishlistTotal productId={product_details.id} />
                                                            <span><i className={viewCount > 0 ? "mdi mdi-eye" : "mdi mdi-eye-outline"}></i>{viewCount}</span>
                                                        </div>
                                                        <div className="row-custom price">
                                                            <div id="product_details_price_container" className="d-inline-block">
                                                                <strong className="lbl-price">
                                                                    {formattedPrice}
                                                                </strong>
                                                            </div>
                                                            <button className="btn btn-contact-seller" data-toggle="modal" data-target="#loginModal"><i className="mdi mdi-comment-question-outline"></i> Ask Question</button>
                                                        </div>
                                                        <div className="row-custom details">
                                                            <div className="item-details">
                                                                <div className="left">
                                                                    <label>Status</label>
                                                                </div>
                                                                <div id="text_product_stock_status" className="right">
                                                                    <span style={{ color: getColorForProductMode(product_details.product_mode) }}>
                                                                {product_details.product_mode}
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form action="#" method="post" id="form_add_cart" noValidate>
                                                    <input type="hidden" name="app_csrf_token" value="a79cf21d16ee862c4617b4b1abe4fa86" />
                                                    <input type="hidden" name="product_id" value="284" />
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="row-custom product-variations">
                                                                    <div className="row row-product-variation item-variation">
                                                                                        <input type="hidden" className="input-product-variation" data-id="252" data-type="radio_button" />
                                                                                        <div className="col-12 col-product-variation">
                                                                                <label className="label-product-variation">Size</label>
                                                                                </div>
                                                                                        {/* Sizes start */}
                                                                                        <ProductSizes />
                                                                                        {/* Sizes end */}
                                                                                        <input type="hidden" className="input-product-variation" data-id="253" data-type="radio_button" />
                                                                                        <div className="col-12 col-product-variation">
                                                                                <label className="label-product-variation">Color</label>
                                                                                </div>
                                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12"></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 product-add-to-cart-container">
                                                                <div className="number-spinner">
                                                                    <div className="input-group">
                                                                        <span className="input-group-btn">
                                                                            <button type="button" className="btn btn-default btn-spinner-minus" data-dir="dwn">-</button>
                                                                        </span>
                                                                        <input type="text" className="form-control text-center" name="product_quantity" value="1" />
                                                                        <span className="input-group-btn">
                                                                            <button type="button" className="btn btn-default btn-spinner-plus" data-dir="up">+</button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="button-container">
                                                                    <button className="btn btn-md btn-block btn-product-cart"><span className="btn-cart-icon"><i className="mdi mdi-cart"></i></span>Add to Cart</button>
                                                                </div>
                                                                        <WishlistButtonDetailsPage productId={product_details.id} />
                                                                                    {/*<div className="button-container button-container-wishlist">
                                                                    <a href="javascript:void(0)" className="btn-wishlist btn-add-remove-wishlist" data-product-id="284" data-type="details"><i className="mdi mdi-heart-outline"></i><span>Add to wishlist</span></a>
                                                                </div>*/}
                                                            </div>
                                                        </div>
                                                        <input type="hidden" name="sysLangId" value="1" />
                                                </form>
                                                <Share />
                                            </div>
                                        </div>
                                                {/* Product details end */}
                                        </div>
                                    </div>

                                    {/* Description, shop details, reviews and comments section end start */}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="product-description post-text-responsive">
                                                <ul className="nav nav-tabs nav-tabs-horizontal" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" id="tab_description" data-toggle="tab" href="#tab_description_content" role="tab" aria-controls="tab_description" aria-selected="true">Description</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="tab_shipping" data-toggle="tab" href="#tab_shipping_content" role="tab" aria-controls="tab_shipping" aria-selected="false">Shop Details</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link " id="tab_reviews" data-toggle="tab" href="#tab_reviews_content" role="tab" aria-controls="tab_reviews" aria-selected="false">Reviews&nbsp;({reviews.length})</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" id="tab_comments" data-toggle="tab" href="#tab_comments_content" role="tab" aria-controls="tab_comments" aria-selected="false">Comments&nbsp;({reviews.length})</a>
                                                    </li>
                                                </ul>
                                                <div id="accordion" className="tab-content">
                                                    <div className="tab-pane fade show active" id="tab_description_content" role="tabpanel">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <a className="card-link" data-toggle="collapse" href="#collapse_description_content">
                                                                    Description
                                                                    <IconDetailsPage name="FaPlus" className="plus" style={{ color: '#777', float: 'left', fontSize: '18px', lineHeight: '1.1rem', marginRight: '5px' }} />
                                                                    <IconDetailsPage name="FaMinus" className="minus" style={{ color: '#777', float: 'left', fontSize: '18px', lineHeight: '1.1rem', marginRight: '5px' }} />
                                                                </a>
                                                            </div>
                                                            <div id="collapse_description_content" className="collapse-description-content collapse show" data-parent="#accordion">
                                                                <div className="description">
                                                                    <ul>
                                                                        <li>{product_details.product_description}</li>
                                                                    </ul>                                            
                                                                </div>
                                                                <div className="row-custom text-right m-b-10">
                                                                    <a href="javascript:void(0)" className="text-muted link-abuse-report" data-toggle="modal" data-target="#reportProductModal">
                                                                        Report this product       
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <ShopDetails sellerId={product_details.seller_id} />
                                                        
                                                    <div className="tab-pane fade" id="tab_reviews_content" role="tabpanel">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <a className="card-link collapsed" data-toggle="collapse" href="#collapse_reviews_content">
                                                                    Reviews
                                                                    <IconDetailsPage name="FaPlus" className="plus" style={{ color: '#777', float: 'left', fontSize: '18px', lineHeight: '1.1rem', marginRight: '5px' }} />
                                                                    <IconDetailsPage name="FaMinus" className="minus" style={{ color: '#777', float: 'left', fontSize: '18px', lineHeight: '1.1rem', marginRight: '5px' }} />
                                                                </a>
                                                            </div>
                                                            <div id="collapse_reviews_content" className="collapse-description-content collapse" data-parent="#accordion">
                                                                <div className="review-result">
                                                                    <div className="reviews-container">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div className="review-total">
                                                                                    <label className="label-review">
                                                                                        Reviews&nbsp;({reviews.length})
                                                                                    </label>
                                                                                </div>
                                                                                {reviews.length === 0 ? (
                                                                                    <p className="no-comments-found">No reviews found!</p>
                                                                                ) : (
                                                                                    <div className="product-details-review">
                                                                                        <div className="rating">
                                                                                            <StarRating rating={averageRating} onRatingChange={() => {}} />
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                    <Reviews productId={product_details.id} />
                                                        {/*<div className="tab-pane fade" id="tab_comments_content" role="tabpanel">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                <a className="card-link collapsed" data-toggle="collapse" href="#collapse_comments_content">
                                                                    Comments
                                                                                                <i className="mdi mdi-plus"></i>
                                                                                                <i className="mdi mdi-minus"></i>
                                                                </a>
                                                            </div>
                                                            <div id="collapse_comments_content" className="collapse-description-content collapse" data-parent="#accordion">
                                                                <input type="hidden" value="5" id="product_comment_limit" />
                                                                <div className="comments-container">
                                                                    <div className="row">
                                                                    <div className="col-12 col-md-6">
                                                                        <div id="comment-result">
                                                                                <div className="row">
                                                                                <div className="col-12">
                                                                                <div className="comments">
                                                                                <div className="row-custom row-comment-label">
                                                                                <label className="label-comment">Comments&nbsp;(0)</label>
                                                                            </div>
                                                            <p className="no-comments-found">No comments found for this product. Be the first to comment!</p>
                                                                </div>
                                                                                </div>
                                                                        </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-md-6">
                                                                    <div className="col-comments-inner">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                        <div className="row-custom row-comment-label">
                                                                            <label className="label-comment">Add a comment</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <form id="form_add_comment">
                                                                            <input type="hidden" name="product_id" value="300" />
                                                                            <div className="form-row">
                                                                                <div className="form-group col-md-6">
                                                                                <input type="text" name="name" id="comment_name" className="form-control form-input" placeholder="Name" />
                                                                            </div>
                                                                            <div className="form-group col-md-6">
                                                                                <input type="email" name="email" id="comment_email" className="form-control form-input" placeholder="Email Address" />
                                                                            </div>
                                                                            </div>
                                                                                <div className="form-group">
                                                                                    <textarea name="comment" id="comment_text" className="form-control form-input form-textarea" placeholder="Comment"></textarea>
                                                                                </div>
                                                                                <div className="form-group">
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <button type="submit" className="btn btn-md btn-custom">Submit</button>
                                                                                </div>
                                                                            </form>
                                                                            <div id="message-comment-result" className="message-comment-result"></div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>*/}
                                                    {/* Next item here*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Description, shop details, reviews and comments section end */}
                                </div>
                                
                                {/* Product image container and details end */}    
                            
                                {/* Related Product Section Begin */}
                                <RelateProducts sellerId={product_details.seller_id} />
                                <RelateProductsCopy section={product_details.product_section} />
                                {/* Related Product Section End */}
                              
                            </div>
                                
                    </div>
                </div>
            )}
            {/* Product Details Section End */}
            
            <ReportProductModal />
      
            <Footer />
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '50vh', // viewport height
};

export default Details;
