import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BsCartCheck } from "react-icons/bs";
import { FaShoppingBasket, FaEye } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer 
} from 'recharts';

interface DashboardStats {
  ordersCount: number;
  productsCount: number;
  sellersCount: number;
  shoppersCount: number;
}

interface OrderData {
  id: number;
  first: string;
  last: string;
  handle: string;
  amount: number;
  status: 'Pending' | 'Completed' | 'Cancelled';
}

const AdminDashboard: React.FC = () => {
    const [stats, setStats] = useState<DashboardStats>({
        ordersCount: 0,
        productsCount: 0,
        sellersCount: 0,
        shoppersCount: 0
    });

    const [orders, setOrders] = useState<OrderData[]>([]);
    const [salesData, setSalesData] = useState<any[]>([]);

    // Simulated data fetching (replace with actual API calls)
    useEffect(() => {
        // Simulate dashboard statistics
        setStats({
            ordersCount: 100,
            productsCount: 23,
            sellersCount: 5,
            shoppersCount: 50
        });

        // Simulated orders
        setOrders([
            { id: 1, first: "Mark", last: "Otto", handle: "@mdo", amount: 250, status: 'Completed' },
            { id: 2, first: "Jacob", last: "Thornton", handle: "@fat", amount: 150, status: 'Pending' },
            { id: 3, first: "Larry", last: "Bird", handle: "@twitter", amount: 350, status: 'Completed' },
        ]);

        // Simulated sales data for chart
        setSalesData([
            { name: 'Jan', sales: 4000 },
            { name: 'Feb', sales: 3000 },
            { name: 'Mar', sales: 5000 },
            { name: 'Apr', sales: 4500 },
            { name: 'May', sales: 6000 },
            { name: 'Jun', sales: 5500 },
        ]);
    }, []);

    const renderOverviewBox = (count: number, label: string, Icon: React.ElementType) => (
        <OverviewBox>
            <IconWrapper>
                <Icon size={30} color="#007bff" />
            </IconWrapper>
            <TextWrapper>
                <StatsNumber>{count}</StatsNumber>
                <StatsLabel>{label}</StatsLabel>
            </TextWrapper>
        </OverviewBox>
    );

    const getStatusColor = (status: string) => {
        switch(status) {
            case 'Completed': return '#28a745';
            case 'Pending': return '#ffc107';
            case 'Cancelled': return '#dc3545';
            default: return '#6c757d';
        }
    };

    return (
        <DashboardWrapper>
            <OverviewContainer>
                {renderOverviewBox(stats.ordersCount, "Total Sales", BsCartCheck)}
                {renderOverviewBox(stats.productsCount, "Products", FaShoppingBasket)}
                {renderOverviewBox(stats.sellersCount, "Sellers", TbTruckDelivery)}
                {renderOverviewBox(stats.shoppersCount, "Shoppers", FaEye)}
            </OverviewContainer>

            <ChartsContainer>
                <ChartCard>
                    <CardHeader>Monthly Sales</CardHeader>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={salesData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Line 
                                type="monotone" 
                                dataKey="sales" 
                                stroke="#007bff" 
                                strokeWidth={3}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </ChartCard>
            </ChartsContainer>

            <RecentOrdersContainer>
                <CardHeader>Recent Orders</CardHeader>
                <OrdersTable>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Customer Name</th>
                            <th>Amount</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order) => (
                            <tr key={order.id}>
                                <td>#{order.id}</td>
                                <td>{order.first} {order.last}</td>
                                <td>${order.amount}</td>
                                <td>
                                    <StatusBadge color={getStatusColor(order.status)}>
                                        {order.status}
                                    </StatusBadge>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </OrdersTable>
            </RecentOrdersContainer>
        </DashboardWrapper>
    );
};

export default AdminDashboard;

// Styled Components
const DashboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #f4f7f6;
`;

const OverviewContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

const OverviewBox = styled.div`
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
    }
`;

const IconWrapper = styled.div`
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const StatsNumber = styled.h3`
    margin: 0;
    font-size: 1.8rem;
    color: #333;
    font-weight: bold;
`;

const StatsLabel = styled.p`
    margin: 5px 0 0;
    color: #6c757d;
    font-size: 0.9rem;
`;

const ChartsContainer = styled.div`
    display: flex;
    gap: 20px;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

const ChartCard = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex: 1;
`;

const CardHeader = styled.h3`
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
    font-size: 1.2rem;
    border-bottom: 2px solid #f4f7f6;
    padding-bottom: 10px;
`;

const RecentOrdersContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const OrdersTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    thead {
        background-color: #f4f7f6;
    }

    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #e9ecef;
    }

    tbody tr:hover {
        background-color: #f8f9fa;
    }
`;

const StatusBadge = styled.span<{ color: string }>`
    background-color: ${props => props.color};
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: bold;
`;