import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { 
  FaEdit, 
  FaTrash, 
  FaEye, 
  FaFilter, 
  FaPlus, 
  FaSearch 
} from "react-icons/fa";

// Types for better type safety
interface Product {
  id: number;
  name: string;
  price: number;
  stock: number;
  status: 'active' | 'inactive' | 'pending';
  category: string;
}

const ProductManagement: React.FC = () => {
  // State management
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('all');

  // Simulated data fetching
  useEffect(() => {
    const mockProducts: Product[] = [
      { 
        id: 1, 
        name: "Wireless Headphones", 
        price: 129.99, 
        stock: 50, 
        status: 'active',
        category: 'Electronics'
      },
      { 
        id: 2, 
        name: "Smart Watch", 
        price: 199.99, 
        stock: 30, 
        status: 'pending',
        category: 'Electronics'
      },
      { 
        id: 3, 
        name: "Laptop Bag", 
        price: 79.99, 
        stock: 20, 
        status: 'inactive',
        category: 'Accessories'
      }
    ];

    setProducts(mockProducts);
    setFilteredProducts(mockProducts);
  }, []);

  // Filtering and searching
  useEffect(() => {
    let result = products;

    // Filter by status
    if (statusFilter !== 'all') {
      result = result.filter(product => product.status === statusFilter);
    }

    // Search filter
    if (searchTerm) {
      result = result.filter(product => 
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.category.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredProducts(result);
  }, [statusFilter, searchTerm, products]);

  // Status color mapping
  const getStatusColor = (status: string) => {
    switch(status) {
      case 'active': return '#28a745';
      case 'inactive': return '#dc3545';
      case 'pending': return '#ffc107';
      default: return '#6c757d';
    }
  };

  // Render methods
  const renderStatusBadge = (status: string) => (
    <StatusBadge color={getStatusColor(status)}>
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </StatusBadge>
  );

  return (
    <ProductsContainer>
      <HeaderSection>
        <PageTitle>Product Management</PageTitle>
        <ActionBar>
          <SearchInput 
            type="text" 
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FilterSelect 
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="all">All Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="pending">Pending</option>
          </FilterSelect>
          <AddProductButton>
            <FaPlus /> Add Product
          </AddProductButton>
        </ActionBar>
      </HeaderSection>

      <ProductTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Category</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map((product) => (
            <tr key={product.id}>
              <td>#{product.id}</td>
              <td>{product.name}</td>
              <td>{product.category}</td>
              <td>${product.price.toFixed(2)}</td>
              <td>{product.stock}</td>
              <td>{renderStatusBadge(product.status)}</td>
              <td>
                <ActionButtons>
                  <EditButton><FaEye /></EditButton>
                  <EditButton><FaEdit /></EditButton>
                  <DeleteButton><FaTrash /></DeleteButton>
                </ActionButtons>
              </td>
            </tr>
          ))}
        </tbody>
      </ProductTable>

      {filteredProducts.length === 0 && (
        <NoResultsMessage>
          No products found matching your search or filter.
        </NoResultsMessage>
      )}
    </ProductsContainer>
  );
};

export default ProductManagement;

// Styled Components
const ProductsContainer = styled.div`
  background-color: #f4f7f6;
  padding: 20px;
  border-radius: 10px;
`;

const HeaderSection = styled.div`
  margin-bottom: 20px;
`;

const PageTitle = styled.h1`
  margin-bottom: 20px;
  color: #333;
  font-size: 1.8rem;
`;

const ActionBar = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const FilterSelect = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const AddProductButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #218838;
  }
`;

const ProductTable = styled.table`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;

  thead {
    background-color: #f8f9fa;
  }

  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e9ecef;
  }

  tbody tr:hover {
    background-color: #f1f3f5;
  }
`;

const StatusBadge = styled.span<{ color: string }>`
  background-color: ${props => props.color};
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: bold;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const EditButton = styled.button`
  background-color: #17a2b8;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled.button`
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoResultsMessage = styled.div`
  text-align: center;
  color: #6c757d;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
`;