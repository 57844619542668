import { api } from "./api";
import { AxiosRequestConfig, AxiosResponse } from "axios";

const apiService = {
    get: async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
        const response: AxiosResponse<T> = await api.get<T>(url, config);
        return response.data; // Return the response data directly
    },

    post: async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
        const response: AxiosResponse<T> = await api.post<T>(url, data, config);
        return response.data; // Return the response data directly
    },

    put: async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
        const response: AxiosResponse<T> = await api.put<T>(url, data, config);
        return response.data; // Return the response data directly
    },

    patch: async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
        const response: AxiosResponse<T> = await api.patch<T>(url, data, config);
        return response.data; // Return the response data directly
    },

    delete: async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
        const response: AxiosResponse<T> = await api.delete<T>(url, config);
        return response.data; // Return the response data directly
    },
};

export { apiService };