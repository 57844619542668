import React from "react";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import { Link } from "react-router-dom";

const AccessDenied = () => {
    // Get the user's status and role from sessionStorage
    const userStatus = sessionStorage.getItem("status") || "inactive";
    const userRole = sessionStorage.getItem("role") || "shopper"; // Default to shopper if role isn't set

    // Determine the content to display based on the status and role
    let title = "Access Denied";
    let message = "Your account does not meet the required status to access this page.";
    let buttonText = "Go to the Homepage";
    let showContactSupport = false; 

    if (userRole === "seller") {
        switch (userStatus) {
            case "blocked":
                title = "Account Blocked";
                message = "Your account has been blocked due to violations of our terms of service. Please contact support for further assistance.";
                showContactSupport = true; 
                break;
            case "pending":
                title = "Verification Pending";
                message = `
                    Your account verification is still in progress. <br />
                    In order to sell your products on this platform, you must be a verified member. <br />
                    Verification is a one-time process. This verification process is necessary because of spammers and fraud. <br />
                    Our team will notify you once the process is complete.
                `;
                break;
            case "inactive":
                title = "Access Denied";
                message = "Your account is currently inactive. Please activate your account to continue.";
                break;
            default:
                break;
        }
    } else if (userRole === "shopper") {
        switch (userStatus) {
            case "blocked":
                title = "Account Blocked";
                message = "Your account has been blocked due to violations of our terms of service. Please contact support for further assistance.";
                showContactSupport = true; // Show Contact Support button
                break;
            case "pending":
                title = "Verification Pending";
                message = "Your account is currently inactive. Please activate your account to continue.";
                break;
            case "inactive":
                title = "Access Denied";
                message = "You do not have permission to access this page.";
                break;
            default:
                break;
        }
    }

    return (
        <>
            <HeaderCopy />

            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-confirm">
                                <h1 className="page-title page-title-product m-b-15">{title}</h1>
                                <h1 className="title" dangerouslySetInnerHTML={{ __html: message }} />
                                {userStatus !== "blocked" && (
                                    <Link to="/" className="btn btn-md btn-custom btn-block m-t-30">
                                        {buttonText}
                                    </Link>
                                )}
                                {showContactSupport && (
                                    <Link to="/contact" className="btn btn-lg btn-custom m-t-10">
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 1792 1792"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#fff"
                                            className="mds-svg-icon"
                                        >
                                            <path d="M896 0q182 0 348 71t286 191 191 286 71 348-71 348-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71zm0 128q-190 0-361 90l194 194q82-28 167-28t167 28l194-194q-171-90-361-90zm-678 1129l194-194q-28-82-28-167t28-167l-194-194q-90 171-90 361t90 361zm678 407q190 0 361-90l-194-194q-82 28-167 28t-167-28l-194 194q171 90 361 90zm0-384q159 0 271.5-112.5t112.5-271.5-112.5-271.5-271.5-112.5-271.5 112.5-112.5 271.5 112.5 271.5 271.5 112.5zm484-217l194 194q90-171 90-361t-90-361l-194 194q28 82 28 167t-28 167z"></path>
                                        </svg>
                                        Contact Support
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default AccessDenied;