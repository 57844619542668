import React from 'react';
import Icon from "../icon/Icon";

const Home = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row g-3 my-2">
                    
                    <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                        <div className="small-box-dashboard small-box-dashboard-first">
                            <h3 className="total">100</h3>
                            <p className="text-muted">Total sales</p>
                            <Icon name="BsCartCheck" color="#9ca9be" />
                        </div>
                    </div>
                        
                    <div className="col-lg-3 col-md-6 col-sm-12 p-1"> 
                        <div className="small-box-dashboard">
                            <h3 className="total">23</h3>
                            <p className="text-muted">Products</p>
                            <Icon name="FaShoppingBasket" color="#9ca9be" />
                        </div>
                    </div>
                        
                    <div className="col-lg-3 col-md-6 col-sm-12 p-1"> 
                        <div className="small-box-dashboard">
                            <h3 className="total">30</h3>
                            <p className="text-muted">Delivered</p>
                            <Icon name="TbTruckDelivery" color="#9ca9be" />
                        </div>
                    </div>
                        
                    <div className="col-lg-3 col-md-6 col-sm-12 p-1"> 
                        <div className="small-box-dashboard">
                            <h3 className="total">0</h3>
                            <p className="text-muted">Page Views</p>
                            <Icon name="FaEye" color="#9ca9be" />
                        </div>
                    </div>

                </div>
            </div>

            <table className="table caption-top bg-white rounded mt-2">
                <caption className="text-dark fs-4">Recent Orders</caption>
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                        <th scope="col">Handle</th>
                    </tr>
                </thead>
                <tbody>
                    {[
                        { id: 1, first: 'Mark', last: 'Otto', handle: '@mdo' },
                        { id: 2, first: 'Jacob', last: 'Thornton', handle: '@fat' },
                        { id: 3, first: 'Larry', last: 'the Bird', handle: '@twitter' },
                    ].map((order) => (
                        <tr key={order.id}>
                        <th scope="row">{order.id}</th>
                        <td>{order.first}</td>
                        <td>{order.last}</td>
                        <td>{order.handle}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    );
};

export default Home;
