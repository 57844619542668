import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PropagateLoader } from "react-spinners";
import Icon from "../../components/icon/Icon";


const SellerShopDetails = ({ token }) => {
    const [sellerInfo, setSellerInfo] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

    // fetch shop details
    useEffect(() => {
        const fetchSellerInfo = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_Base_url}/sellers/get_seller_shop_details`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setSellerInfo(res.data.shop);
        } catch (err) {
            setError('Error fetching seller information');
        } finally {
            setLoading(false);
        }
    };

        fetchSellerInfo();
    }, [token]);

    // handle loading states
    if (loading) {
        return (
            <div style={loaderContainerStyle}>
                <PropagateLoader color="var(--vr-color-main)" loading={loading} />
            </div>
        );
    }

    // handle error states
    if (error) return <div>{error}</div>;

    return (
        <>
            <div className="shop-container"> 
                {error && <p className="text-danger text-center">{error}</p>}
                <ul className="shop-info-list">
                    <li><strong>Shop Name:</strong> {sellerInfo.company_name}</li>
                    <li><strong>Shop Location:</strong> {sellerInfo.location}</li>
                    <li><strong>Business Category:</strong> {sellerInfo.business_category}</li>
                    <li><strong>Shop Description:</strong> {sellerInfo.shop_description}</li>
                    <li>
                        <strong>Verified:</strong> {sellerInfo.verified 
                            ? <Icon name="FcApproval" size="1.5em" color="#09b1ba" style={{ position: 'relative', top: '-1.5px' }} />
                            : <Icon name="FcDisapprove" size="1.5em" style={{ position: 'relative', top: '-1.5px' }} />}
                    </li>
                </ul>
            </div>
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '50vh', // Full viewport height
};

export default SellerShopDetails;