import * as jwt from "jwt-decode";
import axios from "axios";
import { authApi } from "../services/api";

// Enum for different token refresh error types
export enum TokenRefreshError {
    NO_TOKEN = 'NO_TOKEN',
    NETWORK_ERROR = 'NETWORK_ERROR',
    INVALID_RESPONSE = 'INVALID_RESPONSE',
    SERVER_ERROR = 'SERVER_ERROR',
    REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
    ACCOUNT_BLOCKED = 'ACCOUNT_BLOCKED'
}


// Token keys for different roles
export const tokenKeys: Record<Role, string> = {
    'shopper': 'shopper_token',
    'seller': 'seller_token',
    'super-admin': 'super-admin_token'
};

export type Role = 'shopper' | 'seller' | 'super-admin';

// Interface for JWT token payload
interface TokenPayload {
    exp: number;
    iat: number;
}


// Utility functions

// Check if token is expired
export const isTokenExpired = (token: string): boolean => {
    try {
      const decoded = jwt.jwtDecode<TokenPayload>(token);
      return Date.now() >= decoded.exp * 1000;
    } catch (error) {
      return true; // Consider invalid tokens as expired
    }
};


// Get token from session storage
export const getToken = (role: Role): string | null => {
    return sessionStorage.getItem(tokenKeys[role]);
};

// Global refresh promise to prevent multiple simultaneous refresh attempts
let refreshPromise: Promise<void> | null = null;

export const handleTokenExpiration = async (role: Role): Promise<void> => {
    try {
        const token = getToken(role);
    
        // No token found
        if (!token) {
            window.dispatchEvent(new CustomEvent('session-expired', { 
            detail: { 
                role, 
                errorType: TokenRefreshError.NO_TOKEN 
            }
            }));
            throw new Error("No token found");
        }
    
        // Token still valid, no refresh needed
        if (!isTokenExpired(token)) return;
    
        // Prevent multiple concurrent refresh attempts
        if (refreshPromise) {
            await refreshPromise;
            return;
        }
    
        // Create and await token refresh
        refreshPromise = refreshAccessToken(role);
        await refreshPromise;
  
    } catch (error: any) {
        const errorType = getErrorType(error);
        // Dispatch session expired event for specific error types
        if (errorType === TokenRefreshError.ACCOUNT_BLOCKED) {
            console.warn("User account is blocked. Session will be terminated.");
        }

        window.dispatchEvent(new CustomEvent('session-expired', { 
            detail: { 
                role, 
                errorType 
            }
        }));

        throw error;
    } finally {
        refreshPromise = null; // Always clear refresh promise
    }
};

// Determine error type for token refresh
const getErrorType = (error: any): TokenRefreshError => {
    if (error.response) {
        // Server responded with an error status
        if (error.response.status === 401) {
            return TokenRefreshError.REFRESH_TOKEN_EXPIRED;
        }
        if (error.response.status === 403) {
            return TokenRefreshError.ACCOUNT_BLOCKED; // Map 403 to account blocked
        }
        return TokenRefreshError.SERVER_ERROR;
    }
    if (error.request) {
      return TokenRefreshError.NETWORK_ERROR;
    }
    return TokenRefreshError.INVALID_RESPONSE;
};

// Refresh access token
export const refreshAccessToken = async (role: Role): Promise<void> => {
    try {
        const response = await authApi.post('/utils/authentication_refresh', {});
  
        // Validate and store new access token
        if (response.data?.accessToken) {
            sessionStorage.setItem(tokenKeys[role], response.data.accessToken);
        } else {
            throw new Error("Invalid refresh token response");
        }
    } catch (error: any) {
        console.error("Error refreshing access token:", error);

        // If response status is 403, treat as account blocked
        if (error.response?.status === 403) {
            console.warn("Received 403 Forbidden during token refresh.");
            throw new Error("User account is blocked.");
        }

        // Clear session storage for other errors
        sessionStorage.clear();
        throw error;
    }
};