import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";


interface ProtectedRouteProps {
    requiredRole: "super-admin" | "seller" | "shopper"; // Role required for the route
    isVerifiedCheck?: boolean; // Whether to enforce status verification
}

// Utility function to fetch authentication details
const getAuthInfo = () => ({
    adminToken: sessionStorage.getItem("super-admin_token") || "",
    sellerToken: sessionStorage.getItem("seller_token") || "",
    shopperToken: sessionStorage.getItem("shopper_token") || "",
    isVerified: sessionStorage.getItem("status") === "active",
    userRole: sessionStorage.getItem("role") || "", // e.g., "admin", "seller", or "shopper"
});

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredRole, isVerifiedCheck = false }) => {
    const { adminToken, sellerToken, shopperToken, isVerified, userRole } = getAuthInfo();

    // Get the current location for redirecting after login
    const location = useLocation();

    // Handle role-based redirection logic
    switch (requiredRole) {
        case "super-admin":
            if (!adminToken) {
                return <Navigate to="/super-admin/login" replace state={{ from: location }} />;
            }
            break;

        case "seller":
            if (!sellerToken) {
                return <Navigate to="/seller-login" replace state={{ from: location }} />;
            }

            if (isVerifiedCheck && !isVerified) {
                return <Navigate to="/access-denied" replace />;
            }
            break;

        case "shopper":
            if (!shopperToken) {
                return <Navigate to="/login" replace state={{ from: location }} />;
            }

            if (isVerifiedCheck && !isVerified) {
                return <Navigate to="/access-denied" replace />;
            }
            break;

        default:
            console.error(`Invalid role: ${requiredRole}`);
            return <Navigate to="/error" replace />;
    }

    // Render child routes if the user has the required role and passes checks
    return <Outlet />;
};

export default ProtectedRoute;