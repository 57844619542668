import React, { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { TextField, FormControl, Button } from "@mui/material";
import Icon from "../../../components/icon/Icon";
import styled from "styled-components";
import { authApi } from "../../../services/api";


interface LoginResponse {
    message: string; // Define other properties if necessary
}

const AdminLogin = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [identifier, setIdentifier] = useState("");
    const [identifierError, setIdentifierError] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [showIcon, setShowIcon] = useState(false);
    const [loginMessage, setLoginMessage] = useState("");
    const [loginMessageType, setLoginMessageType] = useState(""); // "success" or "error"
    const navigate = useNavigate();

    // toggles the eye icon when user starts inputting
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value);
        setShowIcon(value.length > 0); // Show icon when user starts typing
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // handles login form submission
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setIdentifierError(false);
        setPasswordError(false);

        // Basic validation
        if (!identifier) setIdentifierError(true);
        if (!password) setPasswordError(true);

        if (identifier && password) {
            authApi
            .post(
                '/admin/login', // Use the proxied path 
                { identifier, password },
            )
            .then((res) => {
                setIsSubmitting(false);

                if (res.data.status === 200) {
                    // Display success message
                    setLoginMessage("Login successful!");
                    setLoginMessageType("success");
                    
                    sessionStorage.setItem("super-admin_token", res.data.accessToken);
                    const fullName = `${res.data.user.first_name} ${res.data.user.last_name}`;
                    sessionStorage.setItem("admin_name", fullName);
                    sessionStorage.setItem("role", res.data.user.role);
                    
                    navigate("/super-admin/dashboard");
                }
            })
            .catch((err) => {
                setIsSubmitting(false);

                const axiosError = err as AxiosError;
                if (axiosError.response && axiosError.response.data) {
                    const responseData = axiosError.response.data as LoginResponse;
                    setLoginMessage(responseData.message);
                    setLoginMessageType("error");
                } else {
                    setLoginMessage("An unexpected error occurred.");
                    setLoginMessageType("error");
                }
            });
        } else {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <LoginPage>
                <div className="login-box">
                    <LoginLogo>
                        <Link to="/super-admin/login">
                            <b>New Market</b> Panel
                        </Link>
                    </LoginLogo>
                    <LoginBoxBody>
                        <h4 className="login-box-msg">Login</h4>
                        <form onSubmit={handleSubmit}>
                            <LoginMessage className="text-success">
                                {/* Display success message */}
                                <div id="result-login" className={`font-size-13 ${loginMessageType === 'success' ? 'text-success' : loginMessageType === 'error' ? 'text-danger' : ''}`}>
                                    {loginMessage && (
                                        <div>
                                            {loginMessage}
                                        </div>
                                    )}
                                </div>
                            </LoginMessage>
                            <LoginMessage className="text-warning">
                                {/* Display warning message */}
                                <div id="confirmation-result-login" className={`font-size-13 ${loginMessageType === 'warning' ? 'text-warning' : ''}`}>
                                    {loginMessageType === "warning" && (
                                        <div>
                                            {loginMessage}
                                        </div>
                                    )}
                                </div>
                            </LoginMessage>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Email or phone"
                                    variant="outlined"
                                    color="secondary"
                                    type="text"
                                    size="small"
                                    value={identifier}
                                    error={identifierError}
                                    helperText={identifierError ? "Email or phone is required" : ""}
                                    onChange={e => setIdentifier(e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Password"
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    type={showPassword ? "text" : "password"}
                                    onChange={handleChange}
                                    value={password}
                                    error={passwordError}
                                    helperText={passwordError ? "Password is required" : ""}
                                    InputProps={{
                                    endAdornment: showIcon && (
                                        <ToggleIcon>
                                            <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer', marginLeft: '8px' }}>
                                                {showPassword ? <Icon name="VscEyeClosed" size="1.5em" /> : <Icon name="VscEye" size="1.5em"/>}
                                            </span>
                                        </ToggleIcon>
                                    ),
                                    }}
                                />
                            </FormControl>
                            <StyledButton 
                                variant="outlined" 
                                color="secondary" 
                                type="submit"
                                disabled={isSubmitting} 
                                fullWidth
                            >
                                {isSubmitting ? (
                                    <>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        <span className="visually-hidden">Logging in...</span>
                                    </>
                                ) : (
                                    "Login"
                                )}
                            </StyledButton>
                        </form>
                    </LoginBoxBody>
                    <GoToHomepage>
                        <Link to="/">Go to the Homepage</Link>
                    </GoToHomepage>
                </div>
            </LoginPage>
        </>
    );
};

export default AdminLogin;

const LoginPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  .login-box {
    width: 100%;
    max-width: 400px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
`;

const LoginLogo = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  a {
    text-decoration: none;
    color: #333;
    &:hover {
      color: #007bff;
    }
  }
`;

const LoginBoxBody = styled.div`
  padding: 20px;
`;

const LoginMessage = styled.div`
  font-size: 13px;
  margin-bottom: 10px;
  &.text-success {
    color: #28a745;
  }
  &.text-danger {
    color: #dc3545;
  }
  &.text-warning {
    color: #ffc107;
  }
`;

const ToggleIcon = styled.span`
  cursor: pointer;
  margin-left: 8px;
`;

const GoToHomepage = styled.div`
  text-align: center;
  margin-top: 15px;
  a {
    text-decoration: none;
    color: #007bff;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;