import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import Icon from "../icon/Icon";

const WishlistButton = ({ productId }) => {
    const [isAddedToWishlist, setIsAddedToWishlist] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hearts, setHearts] = useState([]);

    // get user session id from sessin storage
    const getUserSessionId = () => {
        let userSessionId = localStorage.getItem('userSessionId');
        if (!userSessionId) {
        userSessionId = uuidv4();
        localStorage.setItem('userSessionId', userSessionId);
        }
        return userSessionId;
    };

    // check if item is in wishlist table
    useEffect(() => {
        const userSessionId = getUserSessionId();

        const checkWishlistStatus = async () => {
        try {
            const response = await axios.get(
            `${process.env.REACT_APP_Base_url}/shoppers/check_wishlist/${userSessionId}/${productId}`
            );
            setIsAddedToWishlist(response.data.isInWishlist);
        } catch (error) {
            console.error('Error checking wishlist status:', error);
        }
        };

        checkWishlistStatus();
    }, [productId]);

    // handles addition and removal of items in wishlist
    const handleToggleWishlist = useCallback(async (e) => {
        e.preventDefault();
        if (isLoading) return;

        setIsLoading(true);
        const userSessionId = getUserSessionId();

        try {
            if (isAddedToWishlist) {
                // Remove from wishlist
                await axios.post(`${process.env.REACT_APP_Base_url}/products/remove_from_wishlist`, {
                    user_session_id: userSessionId,
                    product_id: productId,
                });
                setIsAddedToWishlist(false);
                //alert('Item removed from wishlist.');
            } else {
                // Add to wishlist
                await axios.post(`${process.env.REACT_APP_Base_url}/products/add_to_wishlist`, {
                    user_session_id: userSessionId,
                    product_id: productId,
            });
            setIsAddedToWishlist(true);
            //alert('Item added to wishlist.');
            
            // Generate floating hearts
            const newHearts = Array(5).fill().map((_, i) => ({
                id: Date.now() + i,
                style: {
                left: `${Math.random() * 60 - 30}px`,
                top: `${Math.random() * -60 - 30}px`,
                animationDuration: `${Math.random() * 1 + 1}s`
                }
            }));
            setHearts(newHearts);

            // Remove hearts after animation
            setTimeout(() => setHearts([]), 2000);
        }
    } catch (error) {
        console.error('Error toggling wishlist item:', error);
    } finally {
        setIsLoading(false);
    }
    }, [isLoading, isAddedToWishlist, productId]);

    return (
        <div>
            <a
                href="javascript:void(0)"
                onClick={handleToggleWishlist} 
                disabled={isLoading}
                className="tooltip-container"
            >
                {!isLoading && (
                    isAddedToWishlist ? (
                        <Icon name="BsHeartFill" size="1.2em" style={{ color: 'red' }} />
                    ) : (
                        <Icon name="BsHeart" size="1.2em" style={{ color: 'inherit' }} />
                    )
                )}
                <div className="tooltip">
                    <span className="tooltip-text">{isAddedToWishlist ? 'Added to Wishlist' : 'Wishlist'}</span>
                    <span className="tooltip-arrow"></span>
                </div>
                {isLoading && <span className="loading-spinner"></span>}
            </a>
            
            {hearts.map((heart) => (
                <div
                    key={heart.id}
                    className="position-absolute text-danger animate-float-away"
                    style={heart.style}
                >
                <Icon name="BsHeartFill" size="0.8em" />
                </div>
            ))}
        </div>
    );
};

WishlistButton.propTypes = {
    productId: PropTypes.number.isRequired,
};

export default WishlistButton;