import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { PuffLoader } from 'react-spinners';
import Icon from "../icon/Icon";

const WishlistButton = ({ productId }) => {
    const [isAddedToWishlist, setIsAddedToWishlist] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // get user session id from session storage
    const getUserSessionId = () => {
        let userSessionId = localStorage.getItem('userSessionId');
        if (!userSessionId) {
        userSessionId = uuidv4();
        localStorage.setItem('userSessionId', userSessionId);
        }
        return userSessionId;
    };

    // check the wishlist table for wishlist items
    useEffect(() => {
        const userSessionId = getUserSessionId();

        const checkWishlistStatus = async () => {
        try {
            const response = await axios.get(
            `${process.env.REACT_APP_Base_url}/shoppers/check_wishlist/${userSessionId}/${productId}`
            );
            setIsAddedToWishlist(response.data.isInWishlist);
        } catch (error) {
            console.error('Error checking wishlist status:', error);
        }
        };

        checkWishlistStatus();
    }, [productId]);

    // handles addition and removal of items to the wishlist table
    const handleToggleWishlist = async () => {
        setIsLoading(true);
        const userSessionId = getUserSessionId();

        try {
        if (isAddedToWishlist) {
            // Remove from wishlist
            await axios.post(`${process.env.REACT_APP_Base_url}/products/remove_from_wishlist`, {
            user_session_id: userSessionId,
            product_id: productId,
            });
            setIsAddedToWishlist(false);
            //alert('Item removed from wishlist.');
        } else {
        // Add to wishlist
        await axios.post(`${process.env.REACT_APP_Base_url}/products/add_to_wishlist`, {
            user_session_id: userSessionId,
            product_id: productId,
        });
        setIsAddedToWishlist(true);
        //alert('Item added to wishlist.');
        }
    } catch (error) {
        console.error('Error toggling wishlist item:', error);
    } finally {
        setIsLoading(false);
    }
    };

    return (
        <div className="button-container button-container-wishlist">
            <a
                href="javascript:void(0)"
                onClick={handleToggleWishlist} 
                disabled={isLoading}
                className="btn-wishlist btn-add-remove-wishlist"
            >
                {isLoading ? (
                    <PuffLoader size={20} color="#000" />
                ) : (
                    <>
                        <Icon 
                            name={isAddedToWishlist ? "BsHeartFill" : "BsHeart"} 
                            style={{ fontSize: '26px', marginRight: '8px', color: isAddedToWishlist ? 'red' : 'inherit' }} 
                        />
                        <span>{isAddedToWishlist ? 'Remove from wishlist' : 'Add to wishlist'}</span>
                    </>
                )}
            </a>
        </div>
    );
};

WishlistButton.propTypes = {
    productId: PropTypes.string.isRequired,
};

export default WishlistButton;