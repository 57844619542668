import { User, ProductProps, PaginationResponse } from "../types/Types";
import { apiService } from "./api.types";
import axios from "axios";

export const adminService = {
    // Get all users (shoppers/sellers)
    getUsers: async (
        type: 'sellers' | 'shoppers',
        page = 1,
        limit = 5,
        searchTerm?: string,
        filter?: string
    ): Promise<PaginationResponse<User>> => {

        const params = new URLSearchParams();
        params.append('page', page.toString());
        params.append('limit', limit.toString());

        if (searchTerm) params.append('searchTerm', searchTerm);
        if (filter) params.append('status', filter);

        const url =
            type === 'sellers'
                ? `/admin/get_all_sellers?${params.toString()}`
                : `/admin/get_all_shoppers?${params.toString()}`;
        try {
            return await apiService.get<PaginationResponse<User>>(url);
        } catch (error) {
            adminService.handleError(error, 'Failed to fetch users');
            throw error; // Rethrow for caller awareness
        }
    },

    // Update user status
    updateUserStatus: async (
        userId: string | number,
        type: 'sellers' | 'shoppers',
        status: string
    ): Promise<User> => {
        try {
            const response = await apiService.patch<User>(`/admin/${type}/${userId}`, { status });
            return response; // Return User object directly
        } catch (error) {
            adminService.handleError(error, `Failed to update user status for user ID ${userId}`);
            throw error; // Rethrow for caller awareness
        }
    },

    // Confirm seller
    confirmSeller: async (userId: string | number): Promise<User> => {
        try {
            return await apiService.patch<User>(`/admin/confirm_seller/${userId}`);
        } catch (error) {
            adminService.handleError(error, `Error confirming seller with ID ${userId}`);
            throw error; // Rethrow for caller awareness
        }
    },

    // Centralized error handling
    handleError: (error: unknown, defaultMessage: string): void => {
        if (axios.isAxiosError(error)) {
            console.error(error.response?.data?.message || defaultMessage);
            throw new Error(error.response?.data?.message || defaultMessage);
        }
        console.error(defaultMessage);
        throw new Error(defaultMessage);
    },
};