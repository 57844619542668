import React, { useState } from "react";
import axios, { AxiosError } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { TextField, FormControl, Button } from "@mui/material";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import Icon from "../../components/icon/Icon";
import FacebookLoginComponent from "../../components/connect/FacebookLoginComponent";
import GoogleLoginComponent from "../../components/connect/GoogleLoginComponent";
import { GoogleOAuthProvider } from '@react-oauth/google';


interface LoginResponse {
    message: string; // Define other properties if necessary
}


const Login = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [identifier, setIdentifier] = useState("");
    const [identifierError, setIdentifierError] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [showIcon, setShowIcon] = useState(false);
    const [loginMessage, setLoginMessage] = useState("");
    const [loginMessageType, setLoginMessageType] = useState(""); // "success" or "error"
    const navigate = useNavigate();

    // toggles the eye icon when user starts inputting
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value);
        setShowIcon(value.length > 0); // Show icon when user starts typing
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Handles form submission of Shoppers
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setIdentifierError(false);
        setPasswordError(false)

        // Basic validation
        if (!identifier) setIdentifierError(true);
        if (!password) setPasswordError(true);

        // Only proceed if there are no validation errors
        if (identifier && password) {
            axios
            .post(`${process.env.REACT_APP_Base_url}/shoppers/login`, {
                identifier,
                password,
            })
            .then((res) => {
                setIsSubmitting(false);

                if (res.data.status === 200) {
                    // Display success message
                    setLoginMessage("Login successful!");
                    setLoginMessageType("success");

                    sessionStorage.setItem("shopper_token", res.data.token);
                    sessionStorage.setItem("shopper_name", res.data.user.first_name);
                    sessionStorage.setItem("role", res.data.user.role);
                    sessionStorage.setItem("status", res.data.user.status);

                    navigate("/"); // navigate to homepage
                } else if (res.data.needActivation) {
                    // Display activation message to the user
                    setIsSubmitting(false);
                    setLoginMessage(res.data.message);
                    setLoginMessageType("warning"); // Show warning for activation
                }
            })
            .catch((err) => {
                setIsSubmitting(false);

                const axiosError = err as AxiosError;
                if (axiosError.response && axiosError.response.data) {
                    const responseData = axiosError.response.data as LoginResponse;
                    setLoginMessage(responseData.message);
                    setLoginMessageType("error");
                } else {
                    setLoginMessage("An unexpected error occurred.");
                    setLoginMessageType("error");
                }
            });
        } else {
            setIsSubmitting(false); // Reset submitting state if validation fails
        }
    };

    return (
        <>
            <HeaderCopy />
        
            {/* Login page start  Start */}
            <section className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="blog-content">
                                <div className="title-wrapper text-center">
                                    <ol className="breadcrumbs d-flex align-items-center justify-content-center" style={{ padding: '0' }}>
                                        <li itemProp="itemListElement">
                                            <a itemProp="item" href="/">
                                                <span itemProp="name">Home</span>
                                                <span className="delimiter" style={{ padding: '0 10px' }}>/</span>
                                            </a>
                                            <meta itemProp="position" content="1" />
                                        </li>
                                        <li itemProp="itemListElement">
                                            <a itemProp="item" href="/login">
                                                <span itemProp="name">Account</span>
                                            </a>
                                            <meta itemProp="position" content="2" />
                                        </li>
                                    </ol>
                                    <h1 className="page-title">Log in</h1>
                                </div>
                               
                                    <div className="auth-box" style={{ background: 'transparent' }}>
                                        <div className="row">
                                            <div className="col-12">
                                                <React.Fragment>
                                                    <form id="login_form" autoComplete="off" onSubmit={handleSubmit}>
                                                        <div className="social-login">
                                                            <FacebookLoginComponent />
                                                            <GoogleOAuthProvider clientId="478207131719-sbloltvpbpr0378ncg56hji5jo7tv9gj.apps.googleusercontent.com">
                                                                <GoogleLoginComponent loginType="shoppers" />
                                                            </GoogleOAuthProvider>
                                                            <div className="form-group m-b-0">
                                                                <p className="p-social-media">Or login with email/phone</p>
                                                            </div>
                                                        </div>
                                                        <div id="result-login" className={`font-size-13 ${loginMessageType === 'success' ? 'text-success' : loginMessageType === 'error' ? 'text-danger' : ''}`}>
                                                            {loginMessage && (
                                                                <div>
                                                                    {loginMessage}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div id="confirmation-result-login" className={`font-size-13 ${loginMessageType === 'warning' ? 'text-warning' : ''}`}>
                                                            {loginMessageType === "warning" && (
                                                                <div>
                                                                    {loginMessage}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <FormControl fullWidth margin="normal">
                                                            <TextField 
                                                                label="Email or phone"
                                                                onChange={e => setIdentifier(e.target.value)}
                                                                variant="outlined"
                                                                color="secondary"
                                                                type="text"
                                                                size="small"
                                                                value={identifier}
                                                                error={identifierError}
                                                                helperText={identifierError ? "Email or phone is required" : ""}
                                                            />
                                                        </FormControl>
                                                        <FormControl fullWidth margin="normal">
                                                            <TextField 
                                                                label="Password"
                                                                onChange={handleChange}
                                                                variant="outlined"
                                                                color="secondary"
                                                                size="small"
                                                                type={showPassword ? "text" : "password"}
                                                                value={password}
                                                                error={passwordError}
                                                                helperText={passwordError ? "Password is required" : ""}
                                                                InputProps={{
                                                                    endAdornment: showIcon && ( // Only show the icon if showIcon is true
                                                                        <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer', marginLeft: '8px' }}>
                                                                            {showPassword ? <Icon name="VscEyeClosed" size="1.5em" /> : <Icon name="VscEye" size="1.5em"/>}
                                                                        </span>
                                                                    ),
                                                                }}
                                                            />
                                                        </FormControl>
                                                        <div className="form-group text-right">
                                                            <a href="/forgot-password" className="link-forgot-password">Forgot Password?</a>
                                                        </div>
                                                        <Button 
                                                            variant="outlined" 
                                                            color="secondary" 
                                                            type="submit"
                                                            fullWidth
                                                            disabled={isSubmitting}
                                                        >
                                                            {isSubmitting ? (
                                                                <>
                                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                                    <span className="visually-hidden">Logging in...</span>
                                                                </>
                                                            ) : (
                                                                "Login"
                                                            )}
                                                        </Button>
                                                        <p className="p-social-media m-0 m-t-5">
                                                            Need an account? <Link className="link" to="/register?role=shopper">Register</Link>
                                                        </p>
                                                    </form>
                                                </React.Fragment>
                                            </div>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                    </div>       
                </div>
            </section>
            {/* Login page end */}
     
            <Footer />
        </>
    );
};

export default Login;