import "./vendor.css";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bluetooth_small from "../../assets/img/categories/slider/bluetooth_small.png";
import arrival_small from "../../assets/img/categories/slider/arrival_small.png";
import clothing_small from "../../assets/img/categories/slider/clothing_small.png";
import shoes_small from "../../assets/img/categories/slider/shoes_small.png";
import accessories_small from "../../assets/img/categories/slider/accessories_small.png";
import bluetooth_large from "../../assets/img/categories/slider/bluetooth_large.jpg";
import arrival_large from "../../assets/img/categories/slider/arrival_large.png";
import clothing_large from "../../assets/img/categories/slider/clothing_large.png";
import shoes_large from "../../assets/img/categories/slider/shoes_large.png";
import accessories_large from "../../assets/img/categories/slider/accessories_large.png";
import Icon from "../icon/Icon";


const CustomPrevButton = ({ onClick }) => (
    <div id="main-slider-nav" className="main-slider-nav">
        <button className="prev slick-arrow" onClick={onClick}>
            <Icon name="BiChevronLeft" />
        </button>
    </div>
);

const CustomNextButton = ({ onClick }) => (
    <div id="main-slider-nav" className="main-slider-nav">
        <button className="next slick-arrow" onClick={onClick}>
            <Icon name="BiChevronRight" />
        </button>
    </div>
);


function SliderComponent() {
    const sliderRef = useRef(null); // Still needed for potential future usage

    const settings = {
        dots: false,
        arrows: true,
        lazyLoad: true,
        //adaptiveHeight: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 15000,
        pauseOnHover: true,
        fade: true,
        cssEase: 'linear',
        prevArrow: <CustomPrevButton />, // Use your custom previous button component
        nextArrow: <CustomNextButton />, // Your custom next button
        beforeChange: (current, next) => {
        },
        afterChange: (current) => {
        }
    };

    return (
    
        <div className="section-slider">
            {/* Desktop/tablet devices */}
            <div className="index-main-slider container container-boxed-slider">
                <div className="row">
                    <div className="slider-container">
                        <div id="main-slider" className="main-slider slick-initialized slick-slider">
                            <div className="slick-list draggable">
                                <Slider {...settings} ref={sliderRef}>

                                    <div className="item lazyloaded slick-slide" style={{ position: 'relative', height: '100%', width: '100%' }}>               
                                        <div className="slideshow__image-wrap">
                                            <div className="slideshow_image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${bluetooth_large})`, transform: 'translateX(0px)', backgroundPosition: 'center top', transition: 'transform 0.5s ease-in-out' }}>
                                                <div className="slideshow_text-wrap">
                                                    <div className="slideshow__text-content">
                                                        <div className="container d-flex h100 justify-content-start align-items-center text-left">
                                                            <div className="slideshow__text-text" style={{ left: '7%', maxWidth: '93%' }}>
                                                                <h2 className="slideshow__title weight-600 size-12 font-heading" style={{ color: '#a27e66', lineHeight: '1.1', letterSpacing: '3px', marginBottom: '25px', fontFamily: 'Josefin Sans, sans-serif' }}>
                                                                    NEW ARRIVALS
                                                                </h2>
                                                                <div className="slideshow__subtitle weight-600 size-45 font-heading" style={{ color: '#000000', lineHeight: '1.1', letterSpacing: '-1px', marginBottom: '30px', fontFamily: 'Raleway' }}>
                                                                    New Portable <br />
                                                                    Bluetooth Speaker
                                                                </div>
                                                                <a href="/products" className="btn btn-secondary btn-medium size-10 weight-700 font-heading btn-link" style={{ color: '#000000', backgroundColor: '#000', borderColor: '#000', letterSpacing: '3px' }}>
                                                                    Shop now
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div className="item lazyloaded slick-slide" style={{ position: 'relative', height: '100%', width: '100%' }}>               
                                        <div className="slideshow__image-wrap">
                                            <div className="slideshow__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${arrival_large})`, transform: 'translateX(0px)', backgroundPosition: 'center top', transition: 'transform 0.5s ease-in-out' }}>
                                                <a href="/products" style={{ display: 'block', height: '100%' }}>
                                                    <div className="container" style={{ position: 'absolute', bottom: '0', left: '0', right: '0', textAlign: 'center' }}>
                                                        <div className="row row-slider-caption align-items-center">
                                                            <div className="col-12">
                                                                <div className="caption">
                                                                    <button className="btn btn-slider" data-animation="fadeInUp" data-delay="0.9s" style={{ backgroundColor: 'rgb(34, 34, 34)', borderColor: 'rgb(34, 34, 34)', color: 'rgb(255, 255, 255)', animationDelay: '0.9s' }}>
                                                                        BUY NOW!
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item lazyloaded slick-slide" style={{ position: 'relative', height: '100%', width: '100%' }}>               
                                        <div className="slideshow__image-wrap">
                                            <div className="slideshow__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${clothing_large})`, transform: 'translateX(0px)', backgroundPosition: 'center top', transition: 'transform 0.5s ease-in-out' }}>
                                                <div className="slideshow__text-wrap">
                                                    <div className="slideshow__text-content">
                                                        <Link to="/category?search=CLOTHING" style={{ display: 'block', height: '100%' }}>
                                                            <div className="container" style={{ position: 'absolute', bottom: '0', left: '0', right: '0', textAlign: 'center' }}>
                                                                <div className="row row-slider-caption align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="caption">
                                                                            <h2 className="title btn btn-slider"  data-animation="fadeInUp" data-delay="0.1s" style={{ color: 'rgb(255, 255, 255)', marginBottom: '140px', backgroundColor: 'transparent', animationDelay: '0.1s', fontFamily: 'Raleway' }}>
                                                                                Men/Women/Kids
                                                                            </h2>
                                                                            <p className="description btn btn-slider" data-animation="fadeInUp" data-delay="0.5s" style={{ color: 'rgb(255, 255, 255)', marginBottom: '0', marginLeft: '-365px', animationDelay: '0.5s' }}>
                                                                                Rock it nice!
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item lazyloaded slick-slide" style={{ position: 'relative', height: '100%', width: '100%' }}>               
                                        <div className="slideshow__image-wrap">
                                            <div className="slideshow__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${shoes_large})`, transform: 'translateX(0px)', backgroundPosition: 'center top', transition: 'transform 0.5s ease-in-out' }}>
                                                <div className="slideshow__text-wrap">
                                                    <div className="slideshow__text-content">
                                                        <Link to="/category?search=SHOES" style={{ display: 'block', height: '100%' }}>
                                                            <div className="container" style={{ position: 'absolute', bottom: '0', left: '0', right: '0', textAlign: 'center' }}>
                                                                <div className="row row-slider-caption align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="caption">
                                                                            <button className="btn btn-slider" data-animation="fadeInUp" data-delay="0.9s" style={{ backgroundColor: 'rgb(255, 255, 255)', borderColor: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', animationDelay: '0.9s' }}>
                                                                                Affordable shoes
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item lazyloaded slick-slide" style={{ position: 'relative', height: '100%', width: '100%' }}>               
                                        <div className="slideshow__image-wrap">
                                            <div className="slideshow__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${accessories_large})`, transform: 'translateX(0px)', backgroundPosition: 'center top', transition: 'transform 0.5s ease-in-out' }}>
                                                <div className="slideshow__text-wrap">
                                                    <div className="slideshow__text-content">
                                                        <Link to="/category?search=ACCESSORIES" style={{ display: 'block', height: '100%' }}>
                                                            <div className="container" style={{ position: 'absolute', bottom: '0', left: '0', right: '0', textAlign: 'center' }}>
                                                                <div className="row row-slider-caption align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="caption">
                                                                            <button className="btn btn-slider" data-animation="fadeInUp" data-delay="0.9s" style={{ backgroundColor: 'rgb(255, 255, 255)', borderColor: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', animationDelay: '0.9s' }}>
                                                                                Accessories Explore!
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            {/* Mobile devices */}
            <div className="container-fluid index-mobile-slider">
                <div className="row">
                    <div className="slider-container">
                        <div id="main-mobile-slider" className="main-slider slick-initialized slick-slider">
                            <div className="slick-list draggable">
                                <Slider {...settings} ref={sliderRef}>
                        
                                    <div className="item lazyloaded slick-slide" style={{ position: 'relative', height: '100%', width: '100%' }}>               
                                        <div className="slideshow__image-wrap">
                                            <div className="slideshow_image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${bluetooth_small})`, transform: 'translateX(0px)', backgroundPosition: 'center top', transition: 'transform 0.5s ease-in-out' }}>
                                                <div className="slideshow_text-wrap">
                                                    <div className="slideshow__text-content">
                                                        <div className="container d-flex h100 justify-content-start align-items-center text-left">
                                                            <div className="slideshow__text-text" style={{ left: '7%', maxWidth: '93%' }}>
                                                                <h2 className="slideshow__title weight-600 size-12 font-heading" style={{ color: '#a27e66', lineHeight: '1.1', letterSpacing: '3px', marginBottom: '25px', fontFamily: 'Josefin Sans, sans-serif' }}>
                                                                    NEW ARRIVALS
                                                                </h2>
                                                                <div className="slideshow__subtitle weight-600 size-45 font-heading" style={{ color: '#000000', lineHeight: '1.1', letterSpacing: '-1px', marginBottom: '30px', fontFamily: 'Raleway' }}>
                                                                    New Portable <br />
                                                                    Bluetooth Speaker
                                                                </div>
                                                                <a href="/products" className="btn btn-secondary btn-medium size-10 weight-700 font-heading btn-link" style={{ color: '#000000', backgroundColor: '#000', borderColor: '#000', letterSpacing: '3px' }}>
                                                                    Shop now
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            
                                    <div className="item lazyloaded slick-slide" style={{ position: 'relative', height: '100%', width: '100%' }}>               
                                        <div className="slideshow__image-wrap">
                                            <div className="slideshow__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${arrival_small})`, transform: 'translateX(0px)', backgroundPosition: 'center top', backgroundColor: 'white', transition: 'transform 0.5s ease-in-out' }}>
                                                <div className="slideshow__text-wrap">
                                                    <div className="slideshow__text-content"> 
                                                        <a href="/products" style={{ display: 'block', height: '100%' }}>
                                                            <div className="container" style={{ position: 'absolute', bottom: '0', left: '0', right: '0', textAlign: 'center' }}>
                                                                <div className="row row-slider-caption align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="caption">
                                                                            <button className="btn btn-slider" data-animation="fadeInUp" data-delay="0.9s" style={{ backgroundColor: 'rgb(34, 34, 34)', borderColor: 'rgb(34, 34, 34)', color: 'rgb(255, 255, 255)', animationDelay: '0.9s' }}>
                                                                                BUY NOW!
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item lazyloaded slick-slide" style={{ position: 'relative', height: '100%', width: '100%' }}>               
                                        <div className="slideshow__image-wrap">
                                            <div className="slideshow__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${clothing_small})`, transform: 'translateX(0px)', backgroundPosition: 'center top', backgroundColor: 'black', transition: 'transform 0.5s ease-in-out' }}>
                                                <div className="slideshow__text-wrap">
                                                    <div className="slideshow__text-content">
                                                        <Link to="/category?search=CLOTHING" style={{ display: 'block', height: '100%' }}>
                                                            <div className="container" style={{ position: 'absolute', bottom: '0', left: '0', right: '0', textAlign: 'center' }}>
                                                                <div className="row row-slider-caption align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="caption">
                                                                            <h2 className="title btn btn-slider" data-animation="fadeInUp" data-delay="0.1s" style={{ color: 'rgb(255, 255, 255)', animationDelay: '0.1s', marginBottom: '100px', backgroundColor: 'transparent', fontFamily: 'Raleway' }}>
                                                                                Men/Women/Kids
                                                                            </h2>
                                                                            <p className="description btn btn-slider" data-animation="fadeInUp" data-delay="0.5s" style={{ color: 'rgb(255, 255, 255)', animationDelay: '0.5s', marginBottom: '0' }}>
                                                                                Rock it nice!
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item lazyloaded slick-slide" style={{ position: 'relative', height: '100%', width: '100%' }}>               
                                        <div className="slideshow__image-wrap">
                                            <div className="slideshow__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${shoes_small})`, transform: 'translateX(0px)', backgroundPosition: 'center top', backgroundColor: 'white', transition: 'transform 0.5s ease-in-out' }}>
                                                <div className="slideshow__text-wrap">
                                                    <div className="slideshow__text-content">
                                                        <Link to="/category?search=SHOES" style={{ display: 'block', height: '100%' }}>
                                                            <div className="container" style={{ position: 'absolute', bottom: '0', left: '0', right: '0', textAlign: 'center' }}>
                                                                <div className="row row-slider-caption align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="caption">
                                                                            <button className="btn btn-slider" data-animation="fadeInUp" data-delay="0.9s" style={{ backgroundColor: 'rgb(255, 255, 255)', borderColor: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', animationDelay: '0.9s' }}>
                                                                                Affordable shoes
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item lazyloaded slick-slide" style={{ position: 'relative', height: '100%', width: '100%' }}>               
                                        <div className="slideshow__image-wrap">
                                            <div className="slideshow__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${accessories_small})`, transform: 'translateX(0px)', backgroundPosition: 'center top', backgroundColor: 'black', transition: 'transform 0.5s ease-in-out' }}>
                                                <div className="slideshow__text-wrap">
                                                    <div className="slideshow__text-content">
                                                        <a href="/products" style={{ display: 'block', height: '100%' }}>
                                                            <div className="container" style={{ position: 'absolute', bottom: '0', left: '0', right: '0', textAlign: 'center' }}>
                                                                <div className="row row-slider-caption align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="caption">
                                                                            <button className="btn btn-slider" data-animation="fadeInUp" data-delay="0.9s" style={{ backgroundColor: 'rgb(255, 255, 255)', borderColor: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)', animationDelay: '0.9s' }}>
                                                                                Explore!
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SliderComponent;