import React, { useEffect, useState } from "react";
import axios from 'axios';
import { RingLoader } from "react-spinners";
import user from "../../assets/img/user.png";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import Icon from '../../components/icon/Icon';


const Shops = () => {
    const [loading, setLoading] = useState(true);
    const [shops, setShops] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);  // Track current page
    const [totalPages, setTotalPages] = useState(1);    // Track total pages

    const fetchShops = async (page = 1) => {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_Base_url}/sellers/all_shops?q=${searchQuery}&page=${page}&limit=32`);
        setShops(response.data.shops);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
        setLoading(false);
    };

    useEffect(() => {
        fetchShops(currentPage);
    }, [searchQuery, currentPage]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);  // Reset to page 1 when search changes
    };
    
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
          setCurrentPage(page);
        }
    };

    const handleUsernameClick = (sellerId) => {
        sessionStorage.setItem('seller_id', sellerId); // Set seller ID in session storage
    };

    return (
        <>
            <HeaderCopy />

            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="nav-breadcrumb" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Shops</li>
                                </ol>
                            </nav>
                            <div className="row m-b-15">
                                <div className="col-6">
                                    <h1 className="page-title">Shops</h1>
                                </div>
                                <div className="col-6">
                                    <div className="shops-search-container">
                                        <div className="search">
                                            <form action="#">
                                                <input 
                                                    type="text" 
                                                    name="q" 
                                                    className="form-control form-input" 
                                                    placeholder="Search for a shop"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                                <button type="submit">
                                                    <Icon name="CiSearch" />
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {loading ? (
                                <div style={loaderContainerStyle}>
                                    <RingLoader color="var(--vr-color-main)" loading={loading} />
                                </div>
                            ) : (
                                <div className="row">
                                    {shops.map(shop => (
                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={shop.seller_id}>
                                            <div className="member-list-item">
                                                <div className="left">
                                                    <a href="/profile">
                                                        <img 
                                                            src={shop.profile_picture || user} 
                                                            alt={`${shop.company_name} logo`}
                                                            className="img-fluid img-profile ls-is-cached lazyloaded"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="right">
                                                    <a 
                                                        href="/profile"
                                                        onClick={() => handleUsernameClick(shop.id)}  // Set seller ID on click
                                                    >
                                                        <p className="username">{shop.company_name}</p>
                                                    </a>
                                                    <p className="text-muted m-b-10">Products: {shop.product_count}</p>
                                                    <p>
                                                        <button className="btn btn-md btn-outline" data-toggle="modal" data-target="#loginModal">
                                                            <Icon name="FaUserPlus" style={{ marginRight: '8px', position: 'relative', top: '-2px' }} />
                                                            Follow
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div> 
                                    ))}
                                </div>
                            )}                     
                           {/* Pagination */}
                            <div className="col-12 m-t-15">
                                <div className="float-right">
                                    <div className="pagination-buttons-container">
                                        <button
                                            className="pagination-button" 
                                            disabled={currentPage === 1} 
                                            onClick={() => handlePageChange(currentPage - 1)}
                                        >
                                            <Icon name="BiChevronLeft" /> Prev
                                        </button>
                                        <span>{currentPage} of {totalPages}</span>
                                        <button
                                            className="pagination-button" 
                                            disabled={currentPage === totalPages} 
                                            onClick={() => handlePageChange(currentPage + 1)}
                                        >
                                            Next <Icon name="BiChevronRight" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '50vh', // Full viewport height
};
    
export default Shops;