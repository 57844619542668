import "./category.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import A from "../../assets/img/categories/products/A.jpg";
import B from "../../assets/img/categories/products/B.jpg";
import C from "../../assets/img/categories/products/C.jpg";
import D from "../../assets/img/categories/products/D.jpg";

const Categories = () => {
    const [totalLivingRoom, setTotalLivingRoom] = useState(0);
    const [totalBathroom, setTotalBathroom] = useState(0);
    const [totalAccessories, setTotalAccessories] = useState(0);
    const [totalKitchen, setTotalKitchen] = useState(0);

    const fetchTotalProducts = async (category, setTotal) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_Base_url}/utils/get_product_by_category_section`, 
                {
                    params: {
                        search: category,  // Pass the category as the search query
                    }
                }
            );
    
            if (response.data.status === 200) {
                setTotal(response.data.total_products);  // Set the total number of products for the category
            } else {
                console.error(`Error fetching products for ${category}:`, response.data.message);
              }
            } catch (error) {
              console.error(`API call failed for ${category}:`, error);
        }
    };
    
    useEffect(() => {
        fetchTotalProducts("Living Room", setTotalLivingRoom); // Fetch for Living Room
        fetchTotalProducts("Bathroom", setTotalBathroom);      // Fetch for Bathroom
        fetchTotalProducts("Accessories", setTotalAccessories); // Fetch for Accessories
        fetchTotalProducts("Kitchen", setTotalKitchen);        // Fetch for Kitchen
    }, []);  // Empty dependency array to run only once when the component mounts

    return (
        <>
            <div id="shopify-section-template--14132842135652__1551929568068" className="shopify-section index-section">
                <div className="container-section collections-section">
                    <div className="wrapper container">
                        <div className="row row-0 products products-grid grid-4 layout-2">
                            <div className="product product-category col-auto">
                                <div className="category__item">
                                    <div className="category__image-wrapper">
                                        <div className="aspect__ratio aspect__ratio-75 aspect__ratio-crop">
                                            <Link to="/category?search=LIVING%20ROOM" className="aspect__ratio-container">
                                                <div className="aspect__ratio-image category__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${A})`, display: 'block' }}></div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="category__content">
                                        <div className="category__details">    
                                            <Link to="/category?search=LIVING%20ROOM">
                                                <div className="more-products">
                                                    {totalLivingRoom} products
                                                </div>
                                                <h2 className="category__title split-text">
                                                    <div className="underline-animation">
                                                        <span>Living Room</span>
                                                    </div>
                                                </h2>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product product-category col-auto">
                                <div className="category__item">
                                    <div className="category__image-wrapper">
                                        <div className="aspect__ratio aspect__ratio-75 aspect__ratio-crop">
                                            <Link to="/category?search=ACCESSORIES" className="aspect__ratio-container">
                                                <div className="aspect__ratio-image category__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${B})`, display: 'block' }}></div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="category__content">
                                        <div className="category__details">
                                            <Link to="/category?search=ACCESSORIES">
                                                <div className="more-products">
                                                    {totalAccessories} products
                                                </div>
                                                <h2 className="category__title split-text">
                                                    <div className="underline-animation">
                                                        <span>Accessories</span>
                                                    </div>
                                                </h2>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product product-category col-auto">
                                <div className="category__item">
                                    <div className="category__image-wrapper">
                                        <div className="aspect__ratio aspect__ratio-75 aspect__ratio-crop">
                                            <Link to="/category?search=BATHROOM" className="aspect__ratio-container">
                                                <div className="aspect__ratio-image category__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${C})`, display: 'block' }}></div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="category__content">
                                        <div className="category__details">
                                            <Link to="/category?search=BATHROOM">
                                                <div className="more-products">
                                                    {totalBathroom} products
                                                </div>
                                                <h2 className="category__title split-text">
                                                    <div className="underline-animation">
                                                        <span>Bathroom</span>
                                                    </div>
                                                </h2>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product product-category col-auto">
                                <div className="category__item">
                                    <div className="category__image-wrapper">
                                        <div className="aspect__ratio aspect__ratio-75 aspect__ratio-crop">
                                            <Link to="/category?search=KITCHEN" className="aspect__ratio-container">
                                                <div className="aspect__ratio-image category__image lazyloaded" data-parent-fit="cover" style={{ backgroundImage: `url(${D})`, display: 'block' }}></div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="category__content">
                                        <div className="category__details">
                                            <Link to="/category?search=KITCHEN">
                                                <div className="more-products">
                                                    {totalKitchen} products
                                                </div>
                                                <h2 className="category__title split-text">
                                                    <div className="underline-animation">
                                                        <span>Kitchen</span>
                                                    </div>
                                                </h2>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Categories;