import React, { useState } from "react";
import styled from "styled-components";
import Icon from "../icon/Icon";
import { ClockLoader } from "react-spinners";

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: ${(props) => (props.disabled ? "#ccc" : "#f9f9f9")}; /* Disabled background color */
    color: ${(props) => (props.disabled ? "#888" : "#333")}; /* Disabled text color */
    font-size: 1rem;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")}; /* Disable cursor */
    transition: all 0.3s;

    &:hover {
        background-color: ${(props) => (props.disabled ? "#ccc" : "#f0f0f0")}; /* Hover effect */
    }

    &:disabled {
        background-color: #ccc; /* Disabled state background */
        color: #888; /* Disabled state text color */
        cursor: not-allowed; /* Disable hover effect */
    }
`;


interface ConfirmSellerButtonProps {
    userId: string | number;
    confirmSeller: (id: string | number) => Promise<void>; // Handle async confirm logic
}

const ConfirmSellerButton: React.FC<ConfirmSellerButtonProps> = ({ userId, confirmSeller }) => {
    const [isConfirming, setIsConfirming] = useState(false); // Loading spinner state
    const [isConfirmed, setIsConfirmed] = useState(false); // Checkmark display state

    // handle button confirmation
    const handleConfirm = async () => {
        setIsConfirming(true); // Start loading
        try {
            await confirmSeller(userId); // Trigger the confirmation action
            setIsConfirmed(true); // Show confirmed state
        } catch (error) {
            console.error("Error confirming seller:", error);
        } finally {
            setIsConfirming(false); // Stop loading
        }
    };

    return (
        <Button
            onClick={(e) => {
                e.stopPropagation();
                handleConfirm();
            }}
            disabled={isConfirming || isConfirmed} // Disable button during/after confirmation
        >
            {isConfirming ? (
                <ClockLoader size={8} color="#fff" />
            ) : isConfirmed ? (
                <>
                    Confirmed <Icon name="FcApproval" size="1.2em" style={{ color: "#28a745" }} />
                </>
            ) : (
                <>
                    <Icon name="BiUserCheck" size="1.2em" style={{ position: "relative", top: "-2px" }} />
                    Confirm Seller
                </>
            )}
        </Button>
    );
};

export default ConfirmSellerButton;