import React, { useRef, useEffect, useState } from "react";
import { Toast } from "primereact/toast";
import { GridLoader } from "react-spinners";
import { Route, Routes, NavLink, Navigate } from "react-router-dom";
import axios from 'axios';
import user from "../../assets/img/user.png";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import ViewProducts from "../view-products/ViewProducts";
import SellerShopDetails from "../seller-shop-details/SellerShopDetails";
import SellerWishlistProducts from "../seller-wishlist-products/SellerWishlistProducts";
import SellerReviews from "../seller-reviews/SellerReviews";
import Followers from "../followers/Followers";
import Following from "../following/Following";
import Icon from '../../components/icon/Icon';

const Profile = () => {
    const [sellerId, setSellerId] = useState(null);
    const toast = useRef(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pageSize] = useState(10); // Number of items per page
    const [allProducts, setAllProducts] = useState(0); // State to hold total number of products
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        shop_description: '',
        created_at: '',
        file: null,
        image_cover: null,
    });

    // retrieve seller's credentials from session storage
    useEffect(() => {
        const id = sessionStorage.getItem('seller_id'); // Retrieve seller ID from session storage
        setSellerId(id);
    }, []);

    // State for reviews and wishlist
    const [totalReviews, setTotalReviews] = useState(0);
    const [sellerReviews, setSellerReviews] = useState([]);
    const [reviewsPage, setReviewsPage] = useState(1); // Current page
    const [totalProducts, setTotalProducts] = useState(0); // total products in wishlist
    const [wishlistProducts, setWishlistProducts] = useState([]);
    const [wishlistPage, setWishlistPage] = useState(1); // current page
    
    // Fetch seller details, reviews, and wishlist products
    useEffect(() => {
        const fetchData = async () => {
            if (!sellerId) return; // Exit if sellerId is not defined
            setLoading(true);  // Set loading to true at the start of the data fetch
            try {
                // Fetch seller details
                const sellerResponse = await axios.get(`${process.env.REACT_APP_Base_url}/sellers/get_seller_info/${sellerId}`,);

                if (sellerResponse.data.user) {
                    setFormData(sellerResponse.data.user);
                } else {
                    showToast('warn', 'Warning', "No seller details found.");
                }

            } catch (error) {
                console.error('Error fetching data:', error);
                handleError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [sellerId]);

    // Fetch reviews and wishlist products
    useEffect(() => {
        const fetchReviewsAndWishlist = async () => {
            if (!sellerId) return; // Exit if sellerId is not defined

            setLoading(true); // Set loading to true before fetching data
            try {
                const [reviewsResponse, wishlistResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_Base_url}/sellers/get_product_reviews/${sellerId}`, {
                        params: { limit: pageSize, offset: (reviewsPage - 1) * pageSize }
                    }),
                    axios.get(`${process.env.REACT_APP_Base_url}/sellers/get_wishlist_product/${sellerId}`, {
                        params: { limit: pageSize, offset: (wishlistPage - 1) * pageSize }
                    })
                ]);
                setSellerReviews(reviewsResponse.data.reviews);
                setTotalReviews(reviewsResponse.data.totalCount);
                setWishlistProducts(wishlistResponse.data.wishlistProducts);
                setTotalProducts(wishlistResponse.data.totalCount);
            } catch (error) {
                handleError(error); // Handle error appropriately
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };

        fetchReviewsAndWishlist();
    }, [sellerId, reviewsPage, wishlistPage]);

    // error handling
    const handleError = (error) => {
        console.error('Error fetching data:', error);
        showToast('error', 'Error', error.message || 'An unexpected error occurred.');
    };

    // Calculate the reviews and wishlists to display based on the current page
    const displayedWishlists = wishlistProducts.slice((wishlistPage - 1) * pageSize, wishlistPage * pageSize);
    const displayedReviews = sellerReviews.slice((reviewsPage - 1) * pageSize, reviewsPage * pageSize);


    // Show Toast notification
    const showToast = (severity, summary, detail) => {
        if (toast.current) {
            toast.current.show({ severity, summary, detail });
        }
    };

    if (loading) {
        return (
            <div style={loaderContainerStyle}>
                <GridLoader color="var(--vr-color-main)" loading={loading} />
            </div>
        );
    }

    // Function to format created_at date
    const formatCreatedAt = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'long', year: 'numeric' }; // e.g., February 2024
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <>
            <HeaderCopy />

            <div id="wrapper">
                <div className="container">
                    {/* Breadcrumb and Profile Details */}
                    <div className="row">
                        <div className="col-12">
                            <nav className="nav-breadcrumb" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">Profile</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* Loading Indicator */}
                    {loading ? (
                        <div style={loaderContainerStyle}>
                            <GridLoader color="var(--vr-color-main)" loading={loading} />
                        </div>
                    ) : (
                        <>
                            {/* Profile Details */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="profile-page-top">
                                        <div className="row-custom">
                                            <div className="profile-details">
                                                <div className="left">
                                                    <img 
                                                        src={formData.profile_picture || user} 
                                                        alt="Profile" 
                                                        className="img-profile"
                                                        style={{ maxWidth: 'none' }}
                                                    />
                                                </div>
                                                <div className="right">
                                                    <div className="row-custom row-profile-username">
                                                        <h1 className="username">
                                                            <NavLink to="/profile"> {formData.company_name} </NavLink>
                                                        </h1>
                                                        <Icon name="FcApproval" className="icon-verified-member" style={{ position: 'relative', top: '-2px' }} />
                                                    </div>
                                                    <div className="row-custom">
                                                        <p className="p-last-seen">
                                                            <span className="last-seen last-seen-online"> 
                                                                <Icon name="FaCircle" color="#85cc84" style={{ marginRight: '5px', position: 'relative', top: '-1.2px' }} /> 
                                                                Last seen: &nbsp;Just Now
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="row-custom user-contact">
                                                        <span className="info">Member since&nbsp; {formatCreatedAt(formData.created_at)}</span>
                                                    </div>
                                                    <div className="profile-rating"></div>
                                                    <div className="row-custom profile-buttons">
                                                        <div className="buttons">
                                                            <button className="btn btn-md btn-outline-gray" data-toggle="modal" data-target="#loginModal">
                                                                <Icon name="FaEnvelope" color="#666" style={{ marginRight: '8px', position: 'relative', top: '-1px' }} />
                                                                Ask Question
                                                            </button>
                                                            <button className="btn btn-md btn-outline-gray" data-toggle="modal" data-target="#loginModal">
                                                                <Icon name="FaUserPlus" color="#666" style={{ marginRight: '8px', position: 'relative', top: '-1px' }} />
                                                                Follow
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="products" className="row-custom"></div>
                                    </div>
                                </div>
                            </div>
                    
                            {/* Products/wishlist/reviews */}
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav nav-tabs nav-tabs-horizontal nav-tabs-profile" role="tablist">
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link" 
                                                to="view-products"
                                                activeClassName="active"
                                            >
                                                Products <span className="count">({allProducts})</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link" 
                                                to="seller-shop-details"
                                                activeClassName="active"
                                            >
                                                Shop Details
                                            </NavLink>
                                        </li>                 
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link" 
                                                to="followers"
                                                activeClassName="active"
                                            >
                                                Followers <span className="count">(0)</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link" 
                                                to="following"
                                                activeClassName="active"
                                            >
                                                Following <span className="count">(0)</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link" 
                                                to="seller-wishlist-products"
                                                activeClassName="active"
                                            >
                                                Wishlist <span className="count">({totalProducts})</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link" 
                                                to="seller-reviews"
                                                activeClassName="active"
                                            >
                                                Reviews <span className="count">({totalReviews})</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                {/* Seller detailed content container */}
                                <div className="col-12">
                                    <div className="profile-tab-content">
                                        <Routes>
                                            <Route 
                                                path="/" 
                                                element={
                                                    <Navigate to="view-products" />
                                                } 
                                            />
                                            <Route 
                                                path="view-products" 
                                                element={
                                                    <ViewProducts 
                                                        sellerId={sellerId}
                                                        setTotalProducts={setAllProducts} // Pass the setter function
                                                    />
                                                } 
                                            />
                                            <Route 
                                                path="seller-shop-details" 
                                                element={
                                                    <SellerShopDetails 
                                                        sellerId={sellerId}
                                                    />
                                                } 
                                            />
                                            <Route path="followers" element={<Followers />} />
                                            <Route path="following" element={<Following />} />
                                            <Route 
                                                path="seller-wishlist-products" 
                                                element={
                                                    <SellerWishlistProducts 
                                                        wishlistProducts={displayedWishlists} // Pass the current page wishlist products
                                                        totalProducts={totalProducts} // Pass total wishlist products
                                                        loading={loading}
                                                        pageSize={pageSize} // Pass page size
                                                        wishlistPage={wishlistPage} // Pass current page
                                                        setWishlistPage={setWishlistPage} // Pass function to update current page
                                                    />
                                                } 
                                            />
                                            <Route 
                                                path="seller-reviews" 
                                                element={
                                                    <SellerReviews 
                                                        sellerReviews={displayedReviews} // Pass the current page reviews
                                                        totalReviews={totalReviews} // Pass total reviews
                                                        loading={loading}
                                                        pageSize={pageSize} // Pass page size
                                                        reviewsPage={reviewsPage} // Pass current page
                                                        setReviewsPage={setReviewsPage} // Pass function to update current page
                                                    />
                                                }
                                            />
                                        </Routes>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <Footer />
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '50vh', // Full viewport height
};

export default Profile;