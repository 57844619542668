import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

// Components
import NavHeader from "../../components/seller dashboard/NavHeader";
import Sidebar from "../../components/seller dashboard/Sidebar";
import Home from "../../components/seller dashboard/Home";
import PostProduct from "../post-product/PostProduct";
import SellerProducts from "../seller-products/SellerProducts";
import ShopSettings from "../shop-settings/ShopSettings";
import SellerDashboardLoader from "../../components/loader/SellerDashboardLoader";


const SellerDashboard = () => {
    const [toggle, setToggle] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [totalProducts, setTotalProducts] = useState(0);
    const [companyName, setCompanyName] = useState(null);
    const [token, setToken] = useState(null);

    // get seller's credentials from session storage
    useEffect(() => {
        const tk = sessionStorage.getItem("seller_token");
        const company = sessionStorage.getItem("company_name");

        if (company) setCompanyName(company);
        if (tk) setToken(tk);
    }, []);

    // dynamically load css 
    useEffect(() => {
        // Dynamically import CSS files
        const loadStyles = async () => {
            await import("./AdminLTE.min.css");
            await import("./main-2.3.min.css");
            await import("./dashboard-2.3.min.css");
            setIsLoading(false); // Set loading to false after styles are loaded
        };
        
        loadStyles();
        
        // Add classes and styles to the body
        document.body.classList.add("skin-black-light", "sidebar-mini", "pace-done");
        document.body.style.height = "auto";
        document.body.style.minHeight = "100%";
        
        return () => {
            // Remove classes and styles from the body
            document.body.classList.remove("skin-black-light", "sidebar-mini", "pace-done");
            document.body.style.height = "";
            document.body.style.minHeight = "";
        
            // Cleanup the CSS files by removing the link elements
            const links = document.querySelectorAll(
                'link[href$="AdminLTE.min.css"], link[href$="main-2.3.min.css"], link[href$="dashboard-2.3.min.css"]'
            );
              links.forEach((link) => link.parentNode.removeChild(link));
        };
    }, []);

    // Show loading indicator while styles are loading
    if (isLoading) {
        return <SellerDashboardLoader loading={isLoading} />;
    }

    // function to toggle sidebar
    const Toggle = () => {
        setToggle(!toggle);
        const isSmallScreen = window.innerWidth < 767;

        if (isSmallScreen) {
            if (document.body.classList.contains("sidebar-open")) {
                document.body.classList.remove("sidebar-open");
            } else {
                document.body.classList.add("sidebar-open");
            }
        } else {
            if (document.body.classList.contains("sidebar-collapse")) {
                document.body.classList.remove("sidebar-collapse");
            } else {
                document.body.classList.add("sidebar-collapse");
            }
        }
    };
    
    return (
        <>
            <div className="wrapper" style={{ height: 'auto', minHeight: '100%' }}>
                
                <NavHeader Toggle={Toggle} /> 
                
                <Sidebar />
                    
                <div className="content-wrapper" style={{ minHeight: '932px' }}>
                    <section className="content">
                        <Routes>
                            <Route path="/" element={<Home />} /> 
                            <Route path="post-product" element={<PostProduct token={token} companyName={companyName} />} />
                            <Route path="seller-products" element={<SellerProducts token={token} setTotalProducts={setTotalProducts} />} />
                            <Route path="shop-settings" element={<ShopSettings token={token} />} />
                        </Routes>
                    </section>
                </div>
            </div>
        </>
    );
};

export default SellerDashboard;

