import axios from "axios";
import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ProductProps } from "../../types/Types";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import A from "../../assets/img/logo.png";
import { CategoryProps } from "../../types/Types";
import Footer from "../../components/footer/Footer";

const EditProduct = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const toast = useRef(null);
  const [categories, setCategories] = useState([]);
  const [sections, setSections] = useState([]);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState(null);
  const [formData, setFormData] = useState({
    product_name: '',
    product_category: '',
    product_description: '',
    product_price: '',
    product_quantity: '',
    product_section: '',
    product_mode: '',
    product_image: null, // Initialize as null or an empty string
  });
  


  
  
  


return (
    <>
      <Toast ref={toast} />

      {/* Navigation Bar*/}
      <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="header__logo">
                <a href="/">
                  <img src={A} alt="" width="auto" height={50} />
                </a>
              </div>
            </div>
          </div>
        </div>
      {/*end header*/}

      {/* Start home */}
      <section className="bg-half page-next-level">
        <div className="bg-overlay" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="text-center text-white">
                <h4 className="text-uppercase title mb-4">Edit A Product</h4>
                <ul className="page-next d-inline-block mb-0">
                  <li>
                    <a href="/view-product" className="text-uppercase font-weight-bold">
                      View My Products
                    </a>
                  </li>
                  <li>
                    <span className="text-uppercase text-white font-weight-bold">
                      Edit A Product
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end home */}

      {/* EDIT A PRODUCT START */}
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="rounded shadow bg-white p-4">
                <div className="custom-form">
                  <div id="message3" />
                  {/*}
                  {productData && (
                    <form onSubmit={handleSubmit}>
                      <h4 className="text-dark mb-3">Edit A Product:</h4>
                      {/* Render form fields using formData values 
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group app-label mt-2">
                            <label className="text-muted">Product Name:</label>
                            <input 
                              type="text"
                              className="form-control resume"
                              name="product_name" 
                              value={formData.product_name} 
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group app-label mt-2">
                            <label className="text-muted">Product Availability:</label>
                            <select
                              className="form-control resume"
                              name="product_mode" 
                              value={formData.product_mode} 
                              onChange={handleChange}
                              required
                            >
                              <option value="In Stock">In Stock</option>
                              <option value="On Preorder">On Preorder</option>
                              <option value="Out of Stock">Out of Stock</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {sections && (
                          <div className="col-md-6">
                            <div className="form-group app-label mt-2">
                              <label className="text-muted">Product Section:</label>
                              <div className="form-button">
                              <select
                                className="nice-select rounded"
                                required
                                name="product_section"
                                value={formData.product_section}
                                onChange={handleChange}
                              >
                                <option>Choose...</option>
                                {sections.map((section: CategoryProps) => (
                                  <option
                                    value={section.product_section_name}
                                    key={section.id}
                                  >
                                    {section.product_section_name}
                                  </option>
                               ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                    {categories && (
                      <div className="col-md-6">
                        <div className="form-group app-label mt-2">
                          <label className="text-muted">Product Category:</label>
                          <div className="form-button">
                            <select
                              className="nice-select rounded"
                              name="product_category"
                              value={formData.product_category}
                              required
                              onChange={handleChange}
                            >
                              <option>Choose...</option>
                              {categories.map((category: CategoryProps) => (
                                <option
                                  value={category.product_category_name}
                                  key={category.id}
                            >
                              {category.product_category_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
        
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group app-label mt-2">
                      <label className="text-muted">Product Quantity:</label>
                      <input 
                         type="number"
                         className="form-control resume"
                         name="product_quantity" 
                         value={formData.product_quantity} 
                         onChange={handleChange}
                         required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group app-label mt-2">
                      <label className="text-muted"> Product Price:</label>
                       <input 
                        type="number"
                        className="form-control resume"
                        name="product_price" 
                        value={formData.product_price} 
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
        
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group app-label mt-2">
                      <label className="text-muted">Product Description:</label>
                        <textarea
                          rows={6}
                          className="form-control resume"
                          name="product_description" 
                          value={formData.product_description} 
                          onChange={handleChange} 
                        />
                      </div>
                    </div>
                  </div>
        
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group app-label mt-2">
                        <label className="text-muted" htmlFor="productImage">Product Image: <span style={{ color: 'red' }}>*</span></label>
                        <input 
                          type="file" 
                          accept="image/*" 
                          onChange={handleImageChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {/* ... other form fields *
                  {/* change button to loading when setLoading is true *
                  <div className="row">
                    <div className="col-lg-12 mt-2">
                      <button
                        type="submit"
                        className="site-btn btn btn-primary btn-lg btn-block"
                        disabled={loading}
                        >
                        {loading ? 'Updating...' : 'Update'}
                      </button>
                      {loading && <div className="loading-spinner"></div>}
                    </div>
                  </div>
                </form>
                  )}*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* EDIT A PRODUCT END */}
  
  {/* footer start */}
   <Footer />
  {/* footer end */}
    </>
);
};

export default EditProduct;
