import axios from "axios";
import React, { useEffect, useState } from "react";
import { ProductProps } from "../../types/Types";
import { Link } from "react-router-dom";
import Stars from '../../components/stars/Stars';
import WishlistButton from "../../components/wishlist button/WishlistButton";
import WishlistTotal from "../../components/wishlist button/WishlistTotal";

const RelateProducts = ({ section }: any) => {
  const [products, setProducts] = useState<ProductProps[]>([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_Base_url}/utils/get_product_by_category_section`,
        {
          params: {
            product_section: section,
          }
        }
      )
      .then((res) => {
        //console.log(res.data);
        setProducts(res.data.product);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [section]);

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'GHS', // Change to 'GHS' for Ghanaian cedis
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

  return (
  <>
<div className="col-12 section section-related-products m-t-30">
  <h3 className="title">You May Also Like</h3>      
  <div className="row row-product">
    {products.length === 0 ? (
      <div className="col-lg-12">
        <p>No products found!</p>
      </div>
    ) : (
      products.map((product: ProductProps) => {
        return (
          
            
          
          <div className="col-6 col-sm-4 col-md-3 col-mds-5 col-product" key={product.id}>
            <div className="product-item">
              <div className="row-custom product-multiple-image">
                <div className="img-product-container">         
                  <img
                    src={product.product_image}
                    alt={product.product_name}
                    className="img-fluid img-product ls-is-cached lazyloaded"
                    loading="lazy"
                  />
                  <div className="thumbnail hover">
                    <img
                      src={product.product_image}
                      alt={product.product_name}
                      className="img-fluid img-product img-second ls-is-cached lazyloaded"
                      loading="lazy"
                    />
                  </div>
                  <div className="actions">
                    <ul>
                      <li>
                        <WishlistButton productId={product.id} />
                      </li>
                      <li>
                        <a href="/cart" className="tooltip-container">
                          <i className="mdi mdi-cart-outline"></i> 
                            <div className="tooltip">
                              <span className="tooltip-text">Add to Cart</span>
                              <span className="tooltip-arrow"></span>
                            </div>
                        </a>
                      </li>
                      <Link to={`/details`} state={{ data: product.id }}>
                      <li>
                        <a href="/details" className="tooltip-container">
                          <i className="mdi mdi-eye-outline"></i> 
                            <div className="tooltip">
                              <span className="tooltip-text">View Details</span>
                              <span className="tooltip-arrow"></span>
                            </div>
                        </a>
                      </li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row-custom item-details">
                <h3 className="product-title">
                  <Link to={`/details`} state={{ data: product.id }}>
                    {product.product_name}
                  </Link>
                </h3>
                <p className="product-user text-truncate">
                  <a href="#">{product.company_name}</a>
                </p>
                <div className="product-item-rating">
                  <div className="rating">
                    <Stars productId={product.id} />
                  </div>
                  <WishlistTotal productId={product.id} />
                </div>
                <Link to={`/details`} state={{ data: product.id }}>
                  <div className="item-meta">
                    <span className="price">{formatPrice(product.product_price)}</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          
          
          );
        })
      )}
    
  </div>
</div>
  </>
);
};

export default RelateProducts;
