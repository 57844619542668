import React, { useState } from 'react';

const NewsletterFooter = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setSuccessMessage('');
        setErrorMessage('');

        // Basic email validation (can be enhanced)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setIsLoading(false);
                setErrorMessage('Please enter a valid email address.');
            return;
        }

        try {
            // Send the email data to the backend
            const response = await fetch(`${process.env.REACT_APP_Base_url}/utils/newsletter_subscribe`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                setSuccessMessage('Thank you for subscribing to our newsletter!');
                setEmail(''); // Clear the email input field
            } else {
                setErrorMessage(data.message || 'An error occurred. Please try again.');
            }
        } catch (error) {
            setErrorMessage('Failed to subscribe. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="footer-widget">
            <div className="newsletter-footer">
                <h4 className="footer-title">Join Our Newsletter</h4>
                <p className="title-desc">Get all the latest information on Events, Sales and Offers.</p>

                <form id="form_newsletter_footer" className="form-newsletter-footer" onSubmit={handleSubmit}>
                    <input
                        type="email"
                        name="email"
                        className="form-input"
                        maxLength={249}
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autocomplete="email"
                        required
                    />
                    <button type="submit" name="submit" className="btn btn-custom" disabled={isLoading}>
                        {isLoading ? 'Subscribing...' : 'Subscribe'}
                    </button>
                </form>

                {/* Success/Failure Messages */}
                {successMessage && <p className="success-message">{successMessage}</p>}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
        </div>
    );
};

export default NewsletterFooter;