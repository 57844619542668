import React, { useState, useEffect } from 'react';
import { messageService } from '../../../services/messageService';
import { Message as MessageType } from '../../../types/Types';
import styled from 'styled-components';

interface ChatListItem {
    role_id: number;
    role_type: 'shopper' | 'seller';
    chat_type: 'Receiver' | 'Sender';
    name: string; // Added name field
}

const Chat = () => {
    const [messages, setMessages] = useState<MessageType[]>([]);
    const [messageText, setMessageText] = useState('');
    const [chatList, setChatList] = useState<ChatListItem[]>([]); // List of chats
    const [selectedChat, setSelectedChat] = useState<ChatListItem | null>(null); // Selected chat

    // Fetch chat list on component load
    useEffect(() => {
        const fetchChatList = async () => {
            try {
                const chatList = await messageService.getChatList(); // Fetch chat list API
                setChatList(chatList);
            } catch (error) {
                console.error('Error fetching chat list:', error);
            }
        };
        fetchChatList();
    }, []);

    // Fetch messages when a chat is selected
    useEffect(() => {
        if (!selectedChat) return;

        const fetchMessages = async () => {
            try {
                const messages = await messageService.getMessages(selectedChat.role_id); // Fetch messages API
                setMessages(messages); // Set messages data directly
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };
        fetchMessages();
    }, [selectedChat]);

    // Send message to selected chat
    const sendMessage = async () => {
        if (!selectedChat || !messageText) return;
    
        try {
            const newMessage: MessageType = await messageService.sendMessage(selectedChat.role_id, messageText);
            setMessages((prev) => [...prev, newMessage]); // Add new message to the list
            setMessageText(''); // Clear input
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <ChatContainer>
            {/* Chat List */}
            <ChatList>
                {chatList.map((chat) => (
                    <ChatItem
                        key={chat.role_id}
                        onClick={() => setSelectedChat(chat)}
                        active={selectedChat?.role_id === chat.role_id}
                    >
                        {chat.name}
                    </ChatItem>
                ))}
            </ChatList>

            <Separator />

            {/* Selected Chat Messages */}
            <ChatContent>
                {selectedChat ? (
                    <>
                        <Header>
                            <Title>Chat with {selectedChat?.name}</Title>
                        </Header>
                        <MessageArea>
                            {messages.length === 0 ? (
                                <NoMessages>No messages in this chat!</NoMessages>
                            ) : (
                                messages.map((msg) => (
                                    <MessageBubble
                                        key={msg.id}
                                        isSender={msg.sender_role === 'shopper'}
                                    >
                                        <MessageSender>{msg.sender_role}</MessageSender>
                                        <MessageText>{msg.message_text}</MessageText>
                                    </MessageBubble>
                                ))
                            )}
                        </MessageArea>
                        <InputArea>
                            <TextInput
                                value={messageText}
                                onChange={(e) => setMessageText(e.target.value)}
                                placeholder="Type a message..."
                            />
                            <SendButton onClick={sendMessage} disabled={!messageText}>
                                Send
                            </SendButton>
                        </InputArea>
                    </>
                ) : (
                    <NoChatSelected>Please select a chat to view messages</NoChatSelected>
                )}
            </ChatContent>
        </ChatContainer>
    );
};

export default Chat;

// Styled Components
const ChatContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
`;

const ChatList = styled.div`
    width: 30%;
    border-right: 1px solid #ccc;
    overflow-y: auto;
`;

const ChatItem = styled.div<{ active: boolean }>`
    padding: 10px;
    cursor: pointer;
    background-color: ${({ active }) => (active ? '#eee' : 'white')};

    &:hover {
        background-color: #f9f9f9;
    }
`;

const ChatContent = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Header = styled.div`
    padding: 10px;
    border-bottom: 1px solid #ccc;
`;

const Title = styled.h2`
    margin: 0;
    font-size: 1.5em;
`;

const MessageArea = styled.div`
    flex: 1;
    padding: 10px;
    overflow-y: auto;
`;

const NoMessages = styled.div`
    text-align: center;
    color: #aaa;
`;

const NoChatSelected = styled.div`
    text-align: center;
    color: #aaa;
    margin-top: 20px;
`;

const InputArea = styled.div`
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
`;

const TextInput = styled.input`
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const SendButton = styled.button`
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
        background-color: #ccc;
    }
`;

const Separator = styled.hr`
    border: none;
    border-top: 1px solid #e0e0e0;
    margin: 10px 0;
`;


const MessageBubble = styled.div<{ isSender: boolean }>`
    align-self: ${(props) => (props.isSender ? 'flex-end' : 'flex-start')};
    max-width: 70%;
    padding: 10px;
    background-color: ${(props) => (props.isSender ? '#007bff' : '#e9ecef')};
    color: ${(props) => (props.isSender ? '#ffffff' : '#000000')};
    border-radius: ${(props) => (props.isSender ? '15px 15px 0 15px' : '15px 15px 15px 0')};
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`;

const MessageSender = styled.div`
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #6c757d;
`;

const MessageText = styled.div`
    font-size: 14px;
`;