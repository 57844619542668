import { useState, useEffect } from "react";
import { PaginationResponse, User } from "../types/Types";

interface UsePaginationReturn<T> {
    data: T[];
    setData: React.Dispatch<React.SetStateAction<T[]>>;
    currentPage: number;
    totalPages: number;
    totalItems: number;
    loading: boolean;
    error: string | null;
    handlePrev: () => void;
    handleNext: () => void;
    setPage: (page: number) => void;
}

const usePagination = <T,>(
    fetchFunction: (page: number, limit: number) => Promise<PaginationResponse<T>>,
    limit: number = 5,
    dependencies: any[] = []
): UsePaginationReturn<T> => {
    const [data, setData] = useState<T[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchData = async (page: number) => {
        setLoading(true);
        setError(null);

        try {
            // Fetch data directly from the fetchFunction
            const response = await fetchFunction(page, limit);

            // Handle the response directly (no ApiResponse wrapper)
            const { data: items, total, currentPage, totalPages } = response;

            if (Array.isArray(items)) {
                setData(items);       // Set the data for the current page
                setTotalItems(total); // Set the total number of items
                setTotalPages(totalPages); // Set the total number of pages
            } else {
                throw new Error("Invalid data format from API");
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : "Failed to fetch data");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage, ...dependencies]);

    const handlePrev = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
    const handleNext = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    const setPage = (page: number) => setCurrentPage(page);

    return {
        data,
        setData,
        currentPage,
        totalPages,
        totalItems,
        loading,
        error,
        handlePrev,
        handleNext,
        setPage,
    };
};

export default usePagination;