import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import A from "../../assets/img/logo.png";
import Footer from "../../components/footer/Footer";
import { ProductProps } from "../../types/Types";

const InactiveProductList = () => {
  const [products, setProducts] = useState([]);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [sellerData, setSellerData] = useState(null);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    const user = sessionStorage.getItem("user_id");
    setUserId(user);

    setToken(tk);
  }, []);

  //console.log(user_id);
  //console.log(token);
  
  useEffect(() => {
    // calling the inactive products api
    axios
      .get(`${process.env.REACT_APP_Base_url}/products/inactive`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setProducts(res.data.product);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fetchInactiveProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Base_url}/products/inactive`
      );

      console.log(response.data);
      if (response.data.status === 200) {
        setProducts(response.data.product);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleViewDetails = async (sellerId: number, productIndex: number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Base_url}/sellers/get_seller_details`,
        {
          headers: {
              Authorization: `Bearer ${token}`,
            },
        }
      );

      console.log(response.data);
      if (response.data.status === 200) {
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          [productIndex]: response.data.user,
      }));
      }
    } catch (error) {
      console.error('Error fetching seller details:', error);
    }
  };


  const handleActivateProduct = async (productId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Base_url}/admin/activate_product`,
        {
          product_id: productId,
        },
        {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        }
      );

      console.log(response);
      alert(response.data.message);

      if (response.data.status === 200) {
        // Assuming you want to refresh the inactive products list after activation
        fetchInactiveProducts();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Base_url}/admin/delete_product`,
        {
          product_id: productId,
        },
        {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        }
      );

      console.log(response);
      alert(response.data.message);

      if (response.data.status === 200) {
        // Assuming you want to refresh the inactive products list after activation
        fetchInactiveProducts();
      }
    } catch (err) {
      console.error(err);
    }
  };

      // After activation, fetch the updated list of inactive products
      // Assuming you want to refresh the inactive products list after activation
      // You can make another API call to fetch the updated list of inactive products
      // and update the state accordingly.

  return (
    <>
      {/* Navigation Bar*/}
      <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="header__logo">
                <a href="/">
                  <img src={A} alt="" width="auto" height={50} />
                </a>
              </div>
            </div>
          </div>
        </div>
      {/*end header*/}

      {/* Start home */}
      <section className="bg-half page-next-level">
        <div className="bg-overlay" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="text-center text-white">
                <h4 className="text-uppercase title mb-4">Admin Dashboard</h4>
                <ul className="page-next d-inline-block mb-0">
                  <li>
                    <a href="/admin-dash" className="text-uppercase font-weight-bold">
                      Back
                    </a>
                  </li>
                  <li>
                    <span className="text-uppercase text-white font-weight-bold">
                      Inactive Products Dashboard
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end home */}
      
      <section className="featured spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Inactive Products</h2>
              </div>
            </div>
            
            {products.length === 0 ? (
              <div className="col-lg-12">
              <p> No Records Found</p>
              </div>
            ) : (
              products.map((product: ProductProps, productIndex: number) => {
                return (
                  <div className="col-lg-3 col-md-4 col-sm-6" key={product.id}>
                    <div className="featured__item">
                      <Link to={`/product-inactive`} state={{ data: product.id }}>
                        <img
                          src={product.product_image}
                          alt={product.product_name}
                          width="100%"
                          height="auto"
                        />
                        <div className="featured__item__text">
                          <h6>
                            <Link to={`/product-inactive`} state={{ data: product.id }}>
                              {product.product_name}
                            </Link>
                          </h6>
                          <h6>{product.product_category}</h6>
                          <p>{product.product_description}</p>
                          <h5>Quantity: {product.product_quantity}</h5>
                          <h5>GH₵ {product.product_price}</h5>
                          <h5>Seller ID: {product.seller_id}</h5>

                          <div className="row justify-content-center">
                            <div className="col-lg-6 d-flex justify-content-center">
                              <div className="col-lg-10">
                                <div className="rounded shadow bg-white p-4">
                                  <div className="buy-button">
                                    <button className="btn btn-primary" onClick={() => handleViewDetails(product.seller_id, productIndex)}
                                      >
                                        <i className="mdi mdi-eye" /> View Details
                                    </button>
                             
                              {userDetails[productIndex] && (
                                <div>
                                  <p>First Name: {userDetails[productIndex].first_name}</p>
                                  <p>Last Name: {userDetails[productIndex].last_name}</p>
                                  <p>Email: {userDetails[productIndex].email}</p>
                                  <p>Company Name: {userDetails[productIndex].company_name}</p>
                                  <p>Location: {userDetails[productIndex].location}</p>
                                  <p>Contact: {userDetails[productIndex].contact}</p>
                                  <p>Business Category: {userDetails[productIndex].business_category}</p>
                                </div>
                               )}
                              </div>
                            
                    
                          
                          <div className="col-lg-6 d-flex justify-content-center">
                            <div className="buy-button">
                              <div className="btn btn-primary" onClick={() => handleActivateProduct(product.id)}>
                                <i className="mdi mdi-checkbox-marked-circle-outline" /> Activate Product
                              </div>
                            </div>
                          </div>
                                  
                          <div className="col-lg-6 d-flex justify-content-center">
                            <div className="buy-button">
                              <div className="btn btn-primary" onClick={() => handleDeleteProduct(product.id)}>
                                <i className="mdi mdi-delete-circle" /> Delete Product
                              </div>
                            </div>
                          </div>
                            </div>
                          </div>
                        </div>
                      </div>
                                  
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </section>

      {/* footer start */}
      <Footer />
      {/* footer end */}
    </>
  );
};

export default InactiveProductList;
