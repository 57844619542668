import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Icon from "../icon/Icon";

const HeroPage = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    // Separate function for searching logic
    const performSearch = async (query: string): Promise<void> => {
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_Base_url}/products/search_product`, {
                params: { product_name: query },
            });

            setLoading(false);

            if (res.data.status === 200) {
                navigate("/search", {
                    state: { data: res.data.product, keyword: query },
                });
                window.scrollTo(0, 0);
            } else {
                alert("No product found");
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    // Debounce logic
    useEffect(() => {
        if (!search) return;

        const delayDebounceFn = setTimeout(() => {
            performSearch(search);
        }, 500); // 500ms delay to debounce

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    // Event handler for form submission
    const handleSearch = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        performSearch(search);
    };

    return (
        <section className="hero hero--animated">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="hero__search">
                            <div className="hero__search__form">
                                <form onSubmit={handleSearch}>
                                    <div className="hero__search__categories">
                                        Looking for something?
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="I am looking for..."
                                        id="input_search_hero"
                                        name="search"
                                        maxLength={300}
                                        pattern=".*\S+.*"
                                        className="form-control input-search"
                                        required
                                        autoComplete="off"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <button
                                        type="submit"
                                        className="btn btn-default btn-search"
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-loader spinner"
                                            >
                                                <line x1="12" y1="2" x2="12" y2="6"></line>
                                                <line x1="12" y1="18" x2="12" y2="22"></line>
                                                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                                                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                                                <line x1="2" y1="12" x2="6" y2="12"></line>
                                                <line x1="18" y1="12" x2="22" y2="12"></line>
                                                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                                                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                                            </svg>
                                        ) : (
                                            <Icon name="CiSearch" />
                                        )}
                                    </button>
                                </form>
                            </div>
                            <a href="tel:0542059074">
                                <div className="hero__search__phone">
                                    <div className="hero__search__phone__icon">
                                        <Icon
                                            name="IoPhonePortraitOutline"
                                            size="1.5em"
                                            style={{
                                                animation: "ralb-spinner 1s ease-in-out infinite",
                                                animationDirection: "alternate-reverse",
                                            }}
                                        />
                                    </div>
                                    <div className="hero__search__phone__text">
                                        <h5>+233 542 059 074</h5>
                                        <span>support time: 24/7</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        {/*<div className="hero__item set-bg">{""}</div>*/}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroPage;
