import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ViewProductsProps, ProductProps } from '../../types/Types';

// Components
import WishlistButtonVertical from '../../components/wishlist button/WishlistButtonVertical';
import WishlistTotal from '../../components/wishlist button/WishlistTotal';
import Stars from '../../components/stars/Stars';
import PriceFilter, { PriceFilterRef } from '../../components/product filter/PriceFilter';
import SettingsLoader from '../../components/loader/SettingsLoader';
import Icon from '../../components/icon/Icon';
import ReportSellerModal from '../../components/modals/ReportSellerModal';


const ViewProducts: React.FC<ViewProductsProps> = ({ sellerId, setTotalProducts }) => {
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [sortCriteria, setSortCriteria] = useState<string>('most_recent');
    const [minPrice, setMinPrice] = useState<number | null>(null);
    const [maxPrice, setMaxPrice] = useState<number | null>(null);
    const priceFilterRef = useRef<PriceFilterRef>(null);
    const [productName, setProductName] = useState(''); // State to store the search input

    // Fetch products by sellerId
    const fetchSellerProducts = async () => {
        
        if (!sellerId) return;
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_Base_url}/sellers/search_products/${sellerId}`, 
              {
                params: {
                    product_name: productName, // Use a dynamic value here
                    page: currentPage,
                    sort: sortCriteria,
                    minPrice: minPrice !== null ? minPrice : undefined,
                    maxPrice: maxPrice !== null ? maxPrice : undefined,
                }
              },
            );
    
            if (response.data.status === 200) {
                setProducts(response.data.products);
                setTotalPages(response.data.total_pages);
                setTotalProducts(response.data.total_count); // Update the total products count/Calculate total products based on the length of the products array
            } else {
                setProducts([]);
                setTotalPages(1);
                setTotalProducts(0); // Reset total products if no products found
            }
        } catch (error) {
            console.error('Error fetching seller products:', error);
        } finally {
        setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        // Fetch products when sellerId or token changes
        fetchSellerProducts();
    }, [sellerId, currentPage, sortCriteria, minPrice, maxPrice, productName]);

    // Handle search button click
    const handleSearch = () => {
        fetchSellerProducts();
    };

    // Function to handle pagination
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    // Function to sort products by highest and lowest prices, most recent and rating
    const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSortCriteria(e.target.value);
        setCurrentPage(1); // Reset to first page on sort change
    };

    // Function to handle the filtering of products by price range
    const handleFilter = (min: number, max: number) => {
        setMinPrice(min);
        setMaxPrice(max);
        setCurrentPage(1); // Reset to first page after filtering
    };

    // Function to clear the minimum price input
    const handleResetMinPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Prevent default behavior
        setMinPrice(null); // Reset minPrice to null
        priceFilterRef.current?.resetMinPrice(); // Call prop function to reset in PriceFilter component
        setCurrentPage(1); // Reset page to 1 to fetch products again
    };

    // Function to clear the maximum price input
    const handleResetMaxPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Prevent default behavior
        setMaxPrice(null); // Reset maxPrice to null
        priceFilterRef.current?.resetMaxPrice(); // Call prop function to reset in PriceFilter component
        setCurrentPage(1); // Reset page to 1 to fetch products again
    };

    // Function to format price
    const formatPrice = (price: number) => {
        // Format the number to two decimal places
        const formattedPrice = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(price);

        // Prepend the cedi symbol
        return `¢${formattedPrice}`;
    };

    return (
        <>
            {/* Seller's Product Section Begin */}
            <div className="row">
                <div className="col-12 col-md-3 col-sidebar-products">
                    <div id="collapseFilters" className="product-filters">
                        <div className="filter-item">
                            <div className="profile-search">
                                <input 
                                    type="search" 
                                    name="search" 
                                    id="input_search_vendor" 
                                    className="form-control form-input profile-search" 
                                    placeholder="Search"
                                    value={productName} // Bind state to the input value
                                    onChange={(e) => setProductName(e.target.value)} // Update state on input change 
                                />
                                <button 
                                    id="btn_search_vendor" 
                                    className="btn btn-default btn-search"
                                    onClick={handleSearch} // Trigger search on button click
                                >
                                    <Icon name="CiSearch" />
                                </button>
                            </div>
                        </div>
                        <PriceFilter ref={priceFilterRef} isLoading={loading} onFilter={handleFilter} />
                    </div>
                    <div className="row-custom m-b-30">
                        <a href="javascript:void(0)" className="text-muted link-abuse-report" data-toggle="modal" data-target="#reportSellerModal">
                            Report this seller
                        </a>
                        {/* Report Seller Modal */}
                        <ReportSellerModal />             
                    </div>
                    <div className="row-custom"></div>
                </div>
                <div className="col-12 col-md-9 col-content-products">
                    <div className="row">
                        <div className="col-12 product-list-header">
                            <div className="filter-reset-tag-container"></div>
                            <div className="product-sort-by">
                                <span className="span-sort-by">Sort By:</span>
                                <div className="sort-select">
                                    <select 
                                        id="select_sort_items" 
                                        className="custom-select" 
                                        value={sortCriteria} 
                                        onChange={handleSortChange}
                                    >
                                        <option value="most_recent">Most Recent</option>
                                        <option value="lowest_price">Lowest Price</option>
                                        <option value="highest_price">Highest Price</option>
                                        <option value="rating">Highest Rating</option>
                                    </select>
                                </div>
                            </div>
                            <button 
                                className="btn btn-filter-products-mobile" 
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#collapseFilters" 
                                aria-expanded="false" 
                                aria-controls="collapseFilters"
                            >
                                <Icon name="FaFilter" />
                                &nbsp;Filter Products   
                            </button>
                        </div>
                    </div>
                    {/* resetting products filter */}
                    <div className="filter-reset-tag-container">
                        {minPrice !== null && (
                            <div className="filter-reset-tag">
                                <div className="left">
                                    <a href="javascript:void(0)" onClick={(e) => {
                                        e.preventDefault(); 
                                        handleResetMinPrice(e);
                                    }}>
                                        <Icon name="MdClose" />
                                    </a>
                                </div>
                                <div className="right">
                                    <span className="reset-tag-title">Price(¢)</span>
                                    <span>Min: {minPrice}</span>
                                </div>
                            </div>
                        )}
                        {maxPrice !== null && (
                            <div className="filter-reset-tag">
                                <div className="left">
                                    <a href="javascript:void(0)" onClick={(e) => {
                                        e.preventDefault();
                                        handleResetMaxPrice(e);
                                    }}>
                                        <Icon name="MdClose" />
                                    </a>
                                </div>
                                <div className="right">
                                    <span className="reset-tag-title">Price(¢)</span>
                                    <span>Max: {maxPrice}</span>
                                </div>
                            </div>
                        )}
                        {(minPrice !== null || maxPrice !== null) && (
                            <a href="javascript:void(0)" className="link-reset-filters" onClick={(e) => {
                                e.preventDefault();
                                handleResetMinPrice(e);
                                handleResetMaxPrice(e);
                            }}>
                                Reset Filters
                            </a>
                        )}
                    </div>
                    {/* End of resetting container */}
                    {loading ? (
                        <SettingsLoader loading={loading} />
                    ) : (
                        <div className="product-list-content">
                            <div className="row row-product">
                                {products.length === 0 ? (
                                    <div className="col-12">
                                        <p className="no-records-found">No products found!</p>
                                    </div>
                                ) : (
                                    products.map((product: ProductProps) => {
                                        return (
                                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-product" key={product.id}>
                                                <div className="product-item">        
                                                    <div className="row-custom product-multiple-image">
                                                        <div className="img-product-container">
                                                            <Link to={`/details`} state={{ data: product.id }}>
                                                                <img
                                                                    src={product.main_image}
                                                                    alt={product.product_name}
                                                                    className="img-fluid img-product ls-is-cached lazyloaded"
                                                                    loading="lazy"
                                                                />
                                                                <div className="thumbnail hover">
                                                                    <img
                                                                        src={product.main_image}
                                                                        alt={product.product_name}
                                                                        className="img-fluid img-product img-second ls-is-cached lazyloaded"
                                                                        loading="lazy"
                                                                    />
                                                                </div>
                                                            </Link>
                                                            <div className="product-item-options">
                                                                <a href="javascript:void(0)" className="item-option btn-add-remove-wishlist" data-bs-toggle="tooltip" data-bs-placement="left" data-product-id={product.id} data-bs-type="list" title="Wishlist" data-original-title="Wishlist">
                                                                    <WishlistButtonVertical productId={product.id} />
                                                                </a>
                                                                <a href="#" className="item-option" data-toggle="tooltip" data-placement="left" data-product-id={product.id} data-reload="0" title="View Options" data-original-title="View Options">
                                                                    <Icon name="IoCartOutline" size="1.5em" />
                                                                </a>
                                                            </div>   
                                                        </div>
                                                    </div>
                                                    <div className="row-custom item-details">
                                                        <h3 className="product-title">
                                                            <Link to={`/details`} state={{ data: product.id }}>
                                                                {product.product_name}
                                                            </Link>
                                                        </h3>
                                                        <p className="product-user text-truncate">
                                                            <a href="#">{product.company_name}</a>
                                                        </p>
                                                        <div className="product-item-rating">
                                                            <div className="rating">
                                                                <Stars productId={product.id} />
                                                            </div>
                                                            <WishlistTotal productId={product.id} />
                                                        </div>
                                                        <div className="item-meta">
                                                            <span className="price">{formatPrice(product.product_price)}</span>
                                                        </div>                                                                  
                                                    </div>            
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    )}
                    <div className="product-list-pagination">
                        {products.length > 0 ? (
                            <div className="float-right">
                                <nav aria-label="Page navigation">
                                    <ul className="pagination">
                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <li
                                                key={index + 1}
                                                className={`page-item ${
                                                currentPage === index + 1 ? 'active' : ''
                                                }`}
                                            >
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChange(index + 1)}
                                                >
                                                    {index + 1}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            {/* Seller's Product Section Begin */}
        </>
    );
};

export default ViewProducts;